import React, { useEffect } from "react"

import GeoFencing from "pages/LocationList/Geo/GeoFencing/GeoFencing"
import EditGeo from "pages/LocationList/Geo/EditGeo"
import { useNavigate, useLocation } from "react-router-dom"
import { Button, Modal } from "reactstrap"
import Navigation from "pages/LocationList/Geo/Navigation"

const Geo = props => {
  const { GeoModal, toggle, location } = props
  const navigate = useNavigate()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get("type")

  useEffect(() => {
    if (!GeoModal) return

    if (!type || type === "null") {
      navigate("?type=edit-geo")
    } else {
      navigate(`?type=${type}`)
    }
  }, [GeoModal])

  return (
    <Modal
      isOpen={GeoModal}
      toggle={toggle}
      size="xl"
      style={{ position: "relative" }}
    >
      <div
        id="gmaps-overlay"
        className="gmaps"
        style={{
          position: "relative",
          height: "80vh",
        }}
      >
        {type === "edit-geo" ? (
          <EditGeo modal={GeoModal} toggle={toggle} location={location} />
        ) : (
          <GeoFencing modal={GeoModal} toggle={toggle} location={location} />
        )}

        {/* relative */}
        <Navigation />
      </div>

      {/* Close model button */}
      <Button
        color="light"
        style={{
          position: "absolute",
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 10,
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
        className="font-size-24 text-center d-flex align-items-center justify-content-center"
        onClick={toggle}
      >
        <i className="mdi mdi-window-close p-0"></i>
      </Button>
    </Modal>
  )
}

export default Geo
