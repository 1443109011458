const locationDummyData = [
  {
    id: 1,
    nameOfLocation: "location 1",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 1",
    assignedStaff: "employee 1",
  },
  {
    id: 2,
    nameOfLocation: "location 2",
    attendanceType: "office",
    locationType: "Hospital",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 2",
    assignedStaff: "employee 2",
  },
  {
    id: 3,
    nameOfLocation: "location 3",
    attendanceType: "office",
    locationType: "Station",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 3",
    assignedStaff: "employee 3",
  },
  {
    id: 4,
    nameOfLocation: "location 4",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 4",
    assignedStaff: "employee 4",
  },
  {
    id: 5,
    nameOfLocation: "location 5",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 5",
    assignedStaff: "employee 5",
  },
  {
    id: 6,
    nameOfLocation: "location 6",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 6",
    assignedStaff: "employee 6",
  },
  {
    id: 7,
    nameOfLocation: "location 7",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 7",
    assignedStaff: "employee 7",
  },
  {
    id: 8,
    nameOfLocation: "location 8",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 8",
    assignedStaff: "employee 8",
  },
  {
    id: 9,
    nameOfLocation: "location 9",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 9",
    assignedStaff: "employee 9",
  },
  {
    id: 10,
    nameOfLocation: "location 10",
    attendanceType: "online",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 10",
    assignedStaff: "employee 10",
  },
  {
    id: 11,
    nameOfLocation: "location 11",
    attendanceType: "visit",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 11",
    assignedStaff: "employee 11",
  },
  {
    id: 12,
    nameOfLocation: "location 12",
    attendanceType: "destination",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 12",
    assignedStaff: "employee 12",
  },
  {
    id: 13,
    nameOfLocation: "location 13",
    attendanceType: "office",
    locationType: "Bank",
    geo: { x: 32.0, y: 19.1 },
    assignedAdmin: "Admin 13",
    assignedStaff: "employee 13",
  },
]

const citiesDummyData = [
  "Cairo",
  "Alex",
  "Giza",
  "Menoufia",
  "Qalyubia",
  "Sohag",
  "Sharqia",
  "Dakahlia",
  "Fayoum",
  "Gharbia",
  "Minya",
  "Aswan",
  "Asyut",
  "Beni Suef",
  "Ismailia",
  "Port Said",
  "Damietta",
  "Matruh",
  "Suez",
  "Qena",
  "Luxor",
  "Ismailia",
]

const locationTypesDummyData = [
  "Egypt",
  "USA",
  "UAE",
  "UK",
  "Saudi Arabia",
  "Jordan",
  "KSA",
  "Qatar",
  "Oman",
  "Bahrain",
  "Lebanon",
  "Yemen",
  "Syria",
  "Palestine",
]

const locationsNamesDummyData = [
  "location 1",
  "location 2",
  "location 3",
  "location 4",
  "location 5",
  "location 6",
  "location 7",
  "location 8",
  "location 9",
  "location 10",
  "location 11",
  "location 12",
  "location 13",
]

const attendanceTypesDummyData = [
  "Bank",
  "Home",
  "Work",
  "Hospital",
  "Station",
  "Other",
]

const assignedAdminDummyData = [
  "Admin 1",
  "Admin 2",
  "Admin 3",
  "Admin 4",
  "Admin 5",
  "Admin 6",
  "Admin 7",
  "Admin 8",
  "Admin 9",
  "Admin 10",
  "Admin 11",
  "Admin 12",
  "Admin 13",
]

const assignedStaffData = [
  "employee 1",
  "employee 2",
  "employee 3",
  "employee 4",
  "employee 5",
  "employee 6",
  "employee 7",
  "employee 8",
  "employee 9",
  "employee 10",
  "employee 11",
  "employee 12",
  "employee 13",
]

export {
  citiesDummyData,
  locationTypesDummyData,
  locationDummyData,
  locationsNamesDummyData,
  attendanceTypesDummyData,
  assignedAdminDummyData,
  assignedStaffData,
}
