import React, { createContext, useState } from "react";
import { trackingEmployees } from "pages/data";

const initialEmployeeId = null;
const initialSelectedEmployee = null;

const SelectedEmployee = createContext({
    selectedEmployee: initialSelectedEmployee,
    employeeId: initialEmployeeId,
    changeSelectedEmployeeId: (id) => { },
});

export const SelectedEmployeeProvider = (props) => {
    const { children } = props;
    const [selectedEmployee, setSelectedEmployee] = useState(initialSelectedEmployee);
    const [employeeId, setEmployeeId] = useState(initialEmployeeId);

    const changeSelectedEmployeeId = (id) => {
        if (employeeId === id) {
            setEmployeeId(null);
            setSelectedEmployee(null);
        } else {
            setEmployeeId(id);
            setSelectedEmployee(() => trackingEmployees.find(employee => employee.id === id));
        }
    };

    const data = { employeeId, selectedEmployee, changeSelectedEmployeeId };

    return (
        <SelectedEmployee.Provider value={data}>{children}</SelectedEmployee.Provider>
    );
};

export default SelectedEmployee;