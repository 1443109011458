import React, { createContext, useState, useEffect } from "react"
import { leaveTypesDummyData, BeneficiariesEmployees } from "pages/data"

const initialSelectedLeaveType = []
const initialSelectedSelectedEmployees = []
const initialSpecificLeaveType = null

const SelectedLeaveType = createContext({
  leaveTypes: [],
  selectedLeaveTypesList: initialSelectedLeaveType,
  toggleSelectedLeaveTypes: leaveTypeData => {},

  // specific leave type
  specificLeaveType: initialSpecificLeaveType,
  toggleSpecificLeaveType: leaveType => {},

  // BeneficiariesModel
  emplyeesList: [],
  selectedEmplyeesList: [],
  setSelecteEmployees: employeeData => {},
  setSelecteAllEmployees: () => {},
})

export const SelectedLeaveTypeProvider = props => {
  const { children } = props

  const [leaveTypes, setLeaveTypes] = useState(leaveTypesDummyData)
  const [selectedLeaveTypesList, setSelectedLeaveTypesList] = useState(
    initialSelectedLeaveType
  )

  // specific leave type
  const [specificLeaveType, setSpecificLeaveType] = useState(
    initialSpecificLeaveType
  )

  // BeneficiariesModel
  const [emplyeesList, setEmplyeesList] = useState(BeneficiariesEmployees)
  const [selectedEmplyeesList, setSelectedEmplyeesList] = useState(
    initialSelectedSelectedEmployees
  )

  const toggleSelectedLeaveTypes = leaveTypeData => {
    let list
    setSelectedLeaveTypesList(prevState => {
      list = prevState
      return prevState
    })
    const leaveTypeExist = list.find(one => one.id === leaveTypeData.id)
    if (leaveTypeExist) {
      setSelectedLeaveTypesList(prevState =>
        prevState.filter(leaveType => leaveType.id !== leaveTypeData.id)
      )
    } else {
      setSelectedLeaveTypesList(prevState => [...prevState, leaveTypeData])
    }
  }

  const toggleSpecificLeaveType = leaveType => setSpecificLeaveType(leaveType)

  const setSelecteEmployees = employeeData => {
    const employeeExist = selectedEmplyeesList.find(
      employee => employee.id === employeeData.id
    )
    if (employeeExist) {
      setSelectedEmplyeesList(prevState =>
        prevState.filter(employee => employee.id !== employeeData.id)
      )
    } else {
      setSelectedEmplyeesList(prevState => [...prevState, employeeData])
    }
  }

  const setSelecteAllEmployees = () => {
    if (selectedEmplyeesList.length === emplyeesList.length) {
      setSelectedEmplyeesList([])
    } else {
      setSelectedEmplyeesList(BeneficiariesEmployees)
    }
  }

  const data = {
    leaveTypes,
    selectedLeaveTypesList,
    toggleSelectedLeaveTypes,

    // specific leave type
    specificLeaveType,
    toggleSpecificLeaveType,

    // BeneficiariesModel
    emplyeesList,
    selectedEmplyeesList,
    setSelecteEmployees,
    setSelecteAllEmployees,
  }

  return (
    <SelectedLeaveType.Provider value={data}>
      {children}
    </SelectedLeaveType.Provider>
  )
}

export default SelectedLeaveType
