import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login2 from "pages/AuthenticationInner/Login2"
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import Dashboard from "pages/Dashboard"
import Staff from "pages/Staff/Staff"
import Location from "pages/LocationList/index"
import Tracking from "pages/Tracking/index"
import LeaveTypes from "pages/Leave/LeaveTypes"
import LeaveList from "pages/Leave/LeaveList"
import VacationTypes from "pages/Leave/VacationTypes"

// UTILITY
// import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import PagesTimeline from "../pages/Utility/pages-timeline";
// import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404"
// import Pages500 from "../pages/Utility/pages-500";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/staff", component: <Staff /> },
  { path: "/location-list", component: <Location /> },
  { path: "/tracking", component: <Tracking /> },
  { path: "/leave/leave-types", component: <LeaveTypes /> },
  { path: "/leave/leave-list", component: <LeaveList /> },
  { path: "/leave/vacation-types", component: <VacationTypes /> },
  { path: "/pages-starter", component: <PagesStarter /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [{ path: "/login", component: <Login2 /> }]

const errorRoutes = [{ path: "*", component: <Pages404 /> }]

export { authProtectedRoutes, publicRoutes, errorRoutes }
