import React, { useContext } from "react"
import classes from "../../index.module.scss"

import { Col, Input, Row } from "reactstrap"

import { avatar } from "pages/data"

import SelectedLeaveType from "pages/Leave/LeaveTypes/context/selectedLeaveTypes"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Or = props => {
  const { toggle } = props
  const { emplyeesList } = useContext(SelectedLeaveType)

  return (
    <>
      <div
        style={{ maxHeight: "60vh", width: "100%" }}
        className={`${classes.hideScrollBar} py-4 overflow-y-auto`}
      >
        <Row
          className={`${classes.employee_list_container_wrapper} mx-2 flex-column`}
        >
          {emplyeesList.map((employee, index) => (
            <Row
              key={index}
              className={` 
                    ${classes.employee_list_container}
                   p-2 p-xl-3`}
              style={{
                border: "1px solid transparent",
              }}
            >
              <Col
                xs={3}
                md={12}
                xl={3}
                className="d-flex align-items-center justify-content-center"
                style={{ position: "relative" }}
              >
                <div
                  className={classes.employee_list_image_background}
                  style={{
                    backgroundColor: "gray",
                  }}
                />
                <img src={avatar} className={classes.employee_list_image} />
              </Col>
              <Col className="px-0">
                <h1 className={classes.employee_name}>{employee.name}</h1>
                <Row className=" gap-1 font-size-12">
                  <span>{employee.jopTitle}</span>
                  <Row className="gap-1 justify-content-start align-items-center">
                    <Col xs="3">
                      <span>{props.t("Balance")}:</span>
                    </Col>
                    <Col xs="4">
                      <span>{employee.balance}</span>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          ))}
        </Row>
      </div>
      <Row className="mt-4">
        <Col>
          <div className="d-flex gap-2">
            <button
              onClick={toggle}
              type="button"
              className="btn btn-cancel text-primary"
            >
              {props.t("Cancel")}
            </button>
          </div>
        </Col>
      </Row>
    </>
  )
}

Or.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Or))
