import React, { useState, useMemo } from "react"
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import TableContainer from "components/Common/TableContainer"

// Column
import {
  UserName,
  PhoneEmail,
  Address,
  WalletBalances,
  JoiningDate,
} from "./StaffCustCol"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const StaffList = props => {
  const {
    staffData,
    selectedEmployees,
    activeLink,
    handleEditEmployee,
    getCheckedEmployeesHandler,
    handelEditManagementLeave,
  } = props

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return (
            <input
              onChange={() =>
                getCheckedEmployeesHandler(cellProps.row.original)
              }
              checked={selectedEmployees.find(
                employee => employee.id === cellProps.row.original.id
              )}
              type="checkbox"
              className="form-check-input"
            />
          )
        },
      },
      {
        Header: props.t("User Name"),
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          const image = cellProps.row.original.image
          return (
            <>
              <img
                src={image}
                alt="user"
                className="rounded-circle me-2"
                width="30px"
                height="30px"
              />
              <UserName {...cellProps} />
            </>
          )
        },
      },
      {
        Header: props.t("Phone / Email"),
        accessor: "phone",
        filterable: true,
        Cell: cellProps => {
          return <PhoneEmail {...cellProps} />
        },
      },
      {
        Header: props.t("Email"),
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Address {...cellProps} />
        },
      },
      {
        Header: props.t("ID"),
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: props.t("Gender"),
        accessor: "gender",
        filterable: true,
        Cell: cellProps => {
          return <WalletBalances {...cellProps} />
        },
      },
      {
        Header: "Manager Assigned",
        accessor: "managerAssigned",
        Cell: cellProps => {
          return <JoiningDate {...cellProps} />
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-bell-ring font-size-16 text-danger me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Deactivate")}</span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Deactivate")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-eye-off font-size-16 text-danger me-1"
                    id="hidetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Hide")}</span>
                  <UncontrolledTooltip placement="top" target="hidetooltip">
                    {props.t("Hide")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const employeeData = cellProps.row.original
                    handleEditEmployee(employeeData)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 text-primary me-1"
                    id="editInformationtooltip"
                  ></i>
                  <span className="text-primary">
                    {props.t("Edit Information")}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target="editInformationtooltip"
                  >
                    {props.t("Edit Information")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const employeeData = cellProps.row.original
                    handelEditManagementLeave(employeeData)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 text-primary me-1"
                    id="managmentLeavetooltip"
                  ></i>
                  <span className="text-primary">
                    {props.t("Management Leave")}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target="managmentLeavetooltip"
                  >
                    {props.t("Management Leave")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [selectedEmployees, props.t]
  )

  return (
    <TableContainer
      columns={columns}
      data={staffData}
      isGlobalFilter={true}
      isAddOptions={false}
      customPageSize={5}
      allListSize={staffData.length}
      activeLink={activeLink}
    />
  )
}

StaffList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(StaffList))
