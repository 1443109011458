import React, { useState, useEffect, useContext } from "react"
import classes from "../index.module.scss"

import { Col, Row, Label } from "reactstrap"
import Select from "react-select"

import SelectedLeaveType from "pages/Leave/LeaveTypes/context/selectedLeaveTypes"

import { trackingEmployees } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
const filterBy = ["name", "jobTitle"]

const HeaderForm = props => {
  const [selectedFilterGroups, setSelectedFilterGroups] = useState("")
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [filterSelectionsOptions, setFilterSelectionsOptions] = useState([])

  const { setSelecteAllEmployees, emplyeesList, selectedEmplyeesList } =
    useContext(SelectedLeaveType)

  function handleFilterSelect(selectedMulti) {
    setSelectedFilter(selectedMulti)
  }

  useEffect(() => {
    const selectOpionDemo = []
    selectedFilterGroups === "name" &&
      trackingEmployees.map(emp =>
        selectOpionDemo.push({ id: emp.id, label: emp.name, name: emp.name })
      )
    selectedFilterGroups === "jobTitle" &&
      trackingEmployees.map(emp =>
        selectOpionDemo.push({
          id: emp.id,
          label: emp.jopTitle,
          jobTitle: emp.jopTitle,
        })
      )
    setFilterSelectionsOptions(selectOpionDemo)
  }, [selectedFilterGroups])

  return (
    <>
      {/* <div className={classes.checkbox_Employees_Container}>
        <input
          type="checkbox"
          className="form-check-input"
          onClick={setSelecteAllEmployees}
          checked={selectedEmplyeesList.length === emplyeesList.length}
        />
      </div> */}
      <Row className="shadow-sm pb-1">
        <Col md={2} className="p-md-0 mb-2 m-md-0">
          <select
            className={`form-select py-3 ${classes.selectInputSearch}`}
            onChange={e => setSelectedFilterGroups(e.target.value)}
          >
            <option value="">{props.t("Filter By")}</option>
            {filterBy.map((one, index) => (
              <option key={index} value={one}>
                {one}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex p-md-0">
          <Select
            value={selectedFilter}
            isMulti={true}
            onChange={e => {
              handleFilterSelect(e)
            }}
            options={filterSelectionsOptions}
            className={`form-control ${classes.searchEmployeeInput} select2-selection p-0 tracking-filter`}
            getOptionLabel={filterSelectionsOptions =>
              filterSelectionsOptions["label"]
            }
            getOptionValue={filterSelectionsOptions =>
              filterSelectionsOptions["id"]
            }
          />
          <Label
            className={`input-group-text bg-primary text-white px-3 py-3 ${classes.searchIcon}`}
            style={{ cursor: "pointer" }}
          >
            <i className="bx bx-search font-size-20" />
          </Label>
        </Col>
      </Row>
    </>
  )
}

HeaderForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(HeaderForm))
