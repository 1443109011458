import React, { useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import {
  locationTypesDummyData,
  locationsNamesDummyData,
  attendanceTypesDummyData,
  assignedAdminDummyData,
  assignedStaffData,
} from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const CreateLocation = props => {
  const { modal, toggle, location } = props
  const [selectedNameOfLocation, setSelectedNameOfLocation] = useState(null)
  const [selectedAttendanceType, setSelectedAttendanceType] = useState(null)
  const [selectedLocationType, setSelectedLocationType] = useState(null)
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState(null)
  const [selectedAssignedStaff, setSelectedAssignedStaff] = useState(null)

  const submitHandler = e => {
    e.preventDefault()
    // TODO: submit logic && checks
    console.log("submitted")
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Location")}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Name Of Location")}
                  </Label>
                  <Select
                    value={selectedNameOfLocation}
                    onChange={selectedOption => {
                      setSelectedNameOfLocation(selectedOption)
                    }}
                    options={locationsNamesDummyData.map(locationName => ({
                      value: locationName,
                      label: locationName,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Attendance Type (S)")}
                  </Label>
                  <Select
                    value={selectedAttendanceType}
                    onChange={selectedOption => {
                      setSelectedAttendanceType(selectedOption)
                    }}
                    options={attendanceTypesDummyData.map(type => ({
                      value: type,
                      label: type,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Location Type")}
                  </Label>
                  <Select
                    value={selectedLocationType}
                    onChange={selectedOption => {
                      setSelectedLocationType(selectedOption)
                    }}
                    options={locationTypesDummyData.map(type => ({
                      value: type,
                      label: type,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Assigned Admin")}
                  </Label>
                  <Select
                    value={selectedAssignedAdmin}
                    onChange={selectedOption => {
                      setSelectedAssignedAdmin(selectedOption)
                    }}
                    options={assignedAdminDummyData.map(one => ({
                      value: one,
                      label: one,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">
                    {props.t("Assigned Staff")}
                  </Label>
                  <Select
                    value={selectedAssignedStaff}
                    onChange={selectedOption => {
                      setSelectedAssignedStaff(selectedOption)
                    }}
                    options={assignedStaffData.map(one => ({
                      value: one,
                      label: one,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="d-flex gap-2">
                <button type="submit" className={`btn btn-primary`}>
                  {props.t("Add Location")}{" "}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

CreateLocation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CreateLocation))
