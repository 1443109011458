import React, { useMemo, useContext } from "react"
import {
  CardBody,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import TableContainer from "components/Common/TableContainer"
import { useNavigate } from "react-router-dom"

import SelectedVacationType from "pages/Leave/VacationTypes/context/selectedVacationTypes"

import { vacationTypesDummyData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const VacationTypeList = props => {
  const {
    toggleCreateEditVacationTypeModel,
    setIsEdit,
    setVacationType,
    toggleBeneficiariesModel,
  } = props
  const navigate = useNavigate()

  const {
    selectedVacationTypesList,
    toggleSelectedVacationTypes,
    toggleSpecificVacationType,
  } = useContext(SelectedVacationType)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return (
            <input
              onChange={() =>
                toggleSelectedVacationTypes(cellProps.row.original)
              }
              checked={selectedVacationTypesList.find(
                vacationType => vacationType.id === cellProps.row.original.id
              )}
              type="checkbox"
              className="form-check-input"
            />
          )
        },
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Balance"),
        accessor: "balance",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("From Date"),
        accessor: "fromDate",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("To Date"),
        accessor: "toDate",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Work During Vacation"),
        accessor: "workDuringVacation",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value ? props.t("Yes") : props.t("No")}</>
        },
      },
      {
        Header: props.t("Annually"),
        accessor: "annually",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value ? props.t("Yes") : props.t("No")}</>
        },
      },
      {
        Header: props.t("Description"),
        accessor: "description",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-bell-ring font-size-16 text-danger me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Deactivate")}</span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Deactivate")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const vacationTypeData = cellProps.row.original
                    toggleSpecificVacationType(vacationTypeData)
                    toggleBeneficiariesModel()
                    navigate("/leave/vacation-types?type=and")
                  }}
                >
                  <i
                    className="fa fas fa-user-friends font-size-16 text-warning me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-warning">
                    {props.t("Beneficiaries")}
                  </span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Beneficiaries")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const vacationTypeData = cellProps.row.original
                    setIsEdit(true)
                    toggleCreateEditVacationTypeModel()
                    setVacationType(vacationTypeData)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 me-1"
                    id="edittooltip"
                  ></i>
                  {props.t("Edit Leave")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit Leave")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [vacationTypesDummyData, props.t]
  )

  return (
    <CardBody>
      <TableContainer
        columns={columns}
        data={vacationTypesDummyData}
        isGlobalFilter={true}
        isAddOptions={false}
        customPageSize={5}
        allListSize={vacationTypesDummyData.length}
      />
    </CardBody>
  )
}

VacationTypeList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(VacationTypeList))
