import React, { useState } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const CreateEditLeaveType = props => {
  const { modal, toggle, isEdit, leaveType } = props

  const [checkedBtns, setCheckedBtns] = useState({
    noticePeriod: false,
    carriedOver: false,
    maximumDurationAllowed: false,
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (leaveType && leaveType.name) || "",
      balance: (leaveType && leaveType.balance) || "",
      description: (leaveType && leaveType.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Name")),
      balance: Yup.string().required(props.t("Please Enter Balance")),
      description: Yup.string().required(props.t("Please Enter Description")),
    }),
    onSubmit: values => {
      // TODO: submit values
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit") : props.t("Add")} {props.t("Leave Type")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Name")}</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder={props.t("Insert Leave Type Name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Balance")}</Label>
                  <Input
                    name="balance"
                    type="number"
                    placeholder={props.t("Insert Leave Type Balance")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.balance || ""}
                    invalid={
                      validation.touched.balance && validation.errors.balance
                        ? true
                        : false
                    }
                  />
                  {validation.touched.balance && validation.errors.balance ? (
                    <FormFeedback type="invalid">
                      {validation.errors.balance}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Description")}</Label>
                  <Input
                    name="description"
                    type="text"
                    placeholder={props.t("Insert Leave Type Description")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description &&
                      validation.errors.description
                        ? true
                        : false
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label
                      className="form-check-label"
                      htmlFor="specificReason"
                    >
                      {props.t("Leaves with a specific reason ?")}
                    </label>
                  </Col>
                  <Col>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="specificReason"
                        defaultChecked
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label
                      className="form-check-label"
                      htmlFor="addAttachments"
                    >
                      {props.t("Add Attachments")}
                    </label>
                  </Col>
                  <Col>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="addAttachments"
                        defaultChecked
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label className="form-check-label" htmlFor="halfDay">
                      {props.t("Is it possible to take half day ?")}
                    </label>
                  </Col>
                  <Col>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="halfDay"
                        defaultChecked
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label
                      className="form-check-label"
                      htmlFor="retroactivelyRecordLeaves"
                    >
                      {props.t("Retroactively Record Leaves ?")}
                    </label>
                  </Col>
                  <Col>
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="retroactivelyRecordLeaves"
                        defaultChecked
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label className="form-check-label" htmlFor="noticePeriod">
                      {props.t("Pre notice period")}
                    </label>
                  </Col>
                  <Col xs="3" lg="4">
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="noticePeriod"
                        checked={checkedBtns.noticePeriod}
                        onClick={() =>
                          setCheckedBtns(prevState => ({
                            ...prevState,
                            noticePeriod: !prevState.noticePeriod,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {checkedBtns.noticePeriod ? (
                      <Input
                        name="balance"
                        type="number"
                        placeholder={props.t(
                          "Insert The Period Before Taking Leave"
                        )}
                        className="mt-1"
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label
                      className="form-check-label"
                      htmlFor="balanceCarriedOver"
                    >
                      {props.t("Is the leave balance carried over")}
                    </label>
                  </Col>
                  <Col xs="3" lg="4">
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="balanceCarriedOver"
                        checked={checkedBtns.carriedOver}
                        onClick={() =>
                          setCheckedBtns(prevState => ({
                            ...prevState,
                            carriedOver: !prevState.carriedOver,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {checkedBtns.carriedOver ? (
                      <Input
                        name="balance"
                        type="number"
                        placeholder={props.t(
                          "Insert The Maximum Duration For Carried Over"
                        )}
                        className="mt-1"
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col lg="6">
                <Row>
                  <Col xs="9" lg="8">
                    <label
                      className="form-check-label"
                      htmlFor="maximumDuration"
                    >
                      {props.t(
                        "Is there a maximum duration allowed for each leave request ?"
                      )}
                    </label>
                  </Col>
                  <Col xs="3" lg="4">
                    <div className="form-check form-switch mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="maximumDuration"
                        checked={checkedBtns.maximumDurationAllowed}
                        onClick={() =>
                          setCheckedBtns(prevState => ({
                            ...prevState,
                            maximumDurationAllowed:
                              !prevState.maximumDurationAllowed,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {checkedBtns.maximumDurationAllowed ? (
                      <Input
                        name="balance"
                        type="number"
                        placeholder={props.t(
                          "Insert The Maximum Duration Allowed"
                        )}
                        className="mt-1"
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex gap-2">
                <button
                  type="submit"
                  className={`btn btn-primary save-customer`}
                >
                  {isEdit ? props.t("Save") : props.t("Add Leave")}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

CreateEditLeaveType.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CreateEditLeaveType))
