// import React, { useContext } from "react";
// import PropTypes from 'prop-types';
// import { Row, Col, } from "reactstrap";

// //swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from "swiper";
// import "../../../../node_modules/swiper/swiper.scss";

// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
// import { connect } from "react-redux";
// import useColor from 'hooks/useColors';
// import classes from "../index.module.scss";
// import { avatar } from 'pages/data';

// import SelectedEmployee from "pages/Tracking/context/selectedEmployee";

// const LoadingContainer = () => <div>Loading...</div>;

// const CardMap = (props) => {
//     const { employees } = props;
//     const { colors } = useColor();
//     const { employeeId, changeSelectedEmployeeId } = useContext(SelectedEmployee);

//     return (
//         <React.Fragment>
//             <Swiper
//                 slidesPerView={1}
//                 spaceBetween={15}
//                 navigation
//                 pagination={{
//                     clickable: true,
//                 }}
//                 breakpoints={{
//                     678: {
//                         slidesPerView: 1,
//                     },
//                     700: {
//                         slidesPerView: 2,
//                     },
//                     1200: {
//                         slidesPerView: 1,
//                     },
//                     1400: {
//                         slidesPerView: 2,
//                     },
//                     1600: {
//                         slidesPerView: 3,
//                     },
//                     2000: {
//                         slidesPerView: 4,
//                     }
//                 }}
//                 loop={false}
//                 modules={[Pagination, Navigation]}
//                 className="owl-carousel owl-theme events navs-carousel px-3" id="team-carousel" dir="ltr" style={{ height: "73vh" }}>
//                 {employees.map((employee, key) => (
//                     <SwiperSlide
//                         key={key}
//                         className={`item d-flex align-items-center justify-content-center pt-3
//                         ${classes.employees_swiper}
//                         `}
//                         style={{
//                             transform: employeeId === employee.id ? "scale(1)" : "scale(0.97)",
//                             filter: employeeId ? employeeId === employee.id ? "none" : "blur(1.5px)" : "none",
//                         }}
//                         onClick={() => changeSelectedEmployeeId(employee.id)}
//                     >
//                         <div
//                             id="gmaps-markers"
//                             className="gmaps-container"
//                             style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden", borderRadius: "16px" }}
//                         >
//                             <Row className='px-4 py-3 align-items-center'
//                                 style={{
//                                     backgroundColor: employee.punchIn && employee.punchOut
//                                         ? colors.punchOut
//                                         : !employee.punchIn
//                                             ? colors.notPunchIn
//                                             : colors.punshIn
//                                 }}
//                             >
//                                 <Col className="px-0" xs={3} style={{ position: 'relative' }}>
//                                     <div
//                                         className={classes.map_employee_list_image_background} />
//                                     <img
//                                         src={avatar}
//                                         className={classes.map_employee_list_image}
//                                     />
//                                 </Col>
//                                 <Col className='text-light text-center px-0' >
//                                     <h1 className="font-size-16" style={{ textTransform: "capitalize" }}>{employee.name}</h1>
//                                     <Row className="pt-2 gap-1 font-size-12">
//                                         <span>{employee.jopTitle}</span>
//                                         <span>{employee.locationType}</span>
//                                     </Row>
//                                 </Col>
//                             </Row>
//                             <Map
//                                 google={props.google}
//                                 style={{
//                                     width: "100%",
//                                     height: "60vh",
//                                     borderEndStartRadius: "16px",
//                                     borderEndEndRadius: "16px",
//                                 }}
//                                 zoom={10}
//                                 initialCenter={{
//                                     lat: employee.coordinates.lat,
//                                     lng: employee.coordinates.lng
//                                 }}
//                             >
//                                 <Marker
//                                     position={{
//                                         lat: employee.coordinates.lat,
//                                         lng: employee.coordinates.lng
//                                     }}
//                                 />
//                                 <InfoWindow>
//                                     {/*  */}
//                                 </InfoWindow>
//                             </Map>
//                         </div>
//                     </SwiperSlide>
//                 ))}
//             </Swiper>
//         </React.Fragment>
//     );
// };

// CardMap.propTypes = {
//     google: PropTypes.object,
//     selectedEmployee: PropTypes.object,
// };

// export default connect(
//     null,
//     {}
// )(
//     GoogleApiWrapper({
//         apiKey: process.env.REACT_APP_MAP_API_KEY,
//         LoadingContainer: LoadingContainer,
//         v: "3",
//     })(CardMap)
// );

// import React, { useContext } from "react"
// import { Row, Col } from "reactstrap"

// //swiper
// import { Swiper, SwiperSlide } from "swiper/react"
// import { Pagination, Navigation } from "swiper"
// import "../../../../node_modules/swiper/swiper.scss"

// import Leaflet from "leaflet"
// import { Map, TileLayer, Marker } from "react-leaflet"
// import "../../../../node_modules/leaflet/dist/leaflet.css"
// import useColor from "hooks/useColors"
// import classes from "../index.module.scss"
// import { avatar } from "pages/data"

// import SelectedEmployee from "pages/Tracking/context/selectedEmployee"

// const CardMap = props => {
//   const { employees } = props
//   const { colors } = useColor()
//   const { employeeId, changeSelectedEmployeeId } = useContext(SelectedEmployee)

//   const customIcon = new Leaflet.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
//     iconSize: [25, 41],
//     iconAnchor: [12, 41],
//     popupAnchor: [1, -34],
//     shadowSize: [41, 41],
//   })

//   return (
//     <Swiper
//       slidesPerView={1}
//       spaceBetween={15}
//       navigation
//       pagination={{
//         clickable: true,
//       }}
//       breakpoints={{
//         678: {
//           slidesPerView: 1,
//         },
//         700: {
//           slidesPerView: 2,
//         },
//         1200: {
//           slidesPerView: 1,
//         },
//         1400: {
//           slidesPerView: 2,
//         },
//         1600: {
//           slidesPerView: 3,
//         },
//         2000: {
//           slidesPerView: 4,
//         },
//       }}
//       loop={false}
//       modules={[Pagination, Navigation]}
//       className="owl-carousel owl-theme events navs-carousel px-3"
//       id="team-carousel"
//       dir="ltr"
//       style={{ height: "73vh" }}
//     >
//       {employees.map((employee, key) => (
//         <SwiperSlide
//           key={key}
//           className={`item d-flex align-items-center justify-content-center pt-3
//     ${classes.employees_swiper}
//   `}
//           style={{
//             transform: employeeId === employee.id ? "scale(1)" : "scale(0.97)",
//             filter: employeeId
//               ? employeeId === employee.id
//                 ? "none"
//                 : "blur(1.5px)"
//               : "none",
//             position: "relative",
//           }}
//           onClick={() => changeSelectedEmployeeId(employee.id)}
//         >
//           <div
//             style={{
//               position: "absolute",
//               width: "100%",
//               height: "100%",
//               overflow: "hidden",
//               borderRadius: "16px",
//             }}
//           >
//             <Row
//               className="px-4 py-3 align-items-center"
//               style={{
//                 backgroundColor:
//                   employee.punchIn && employee.punchOut
//                     ? colors.punchOut
//                     : !employee.punchIn
//                     ? colors.notPunchIn
//                     : colors.punshIn,
//               }}
//             >
//               <Col className="px-0" xs={3} style={{ position: "relative" }}>
//                 <div className={classes.map_employee_list_image_background} />
//                 <img src={avatar} className={classes.map_employee_list_image} />
//               </Col>
//               <Col className="text-light text-center px-0">
//                 <h1
//                   className="font-size-16"
//                   style={{ textTransform: "capitalize" }}
//                 >
//                   {employee.name}
//                 </h1>
//                 <Row className="pt-2 gap-1 font-size-12">
//                   <span>{employee.jopTitle}</span>
//                   <span>{employee.locationType}</span>
//                 </Row>
//               </Col>
//             </Row>
//             <Map
//               center={[employee.coordinates.lat, employee.coordinates.lng]}
//               zoom={10}
//               style={{
//                 height: "calc(100% - 40px)",
//                 borderEndStartRadius: "16px",
//                 borderEndEndRadius: "16px",
//               }}
//             >
//               <TileLayer
//                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                 attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//               />
//               <Marker
//                 position={[employee.coordinates.lat, employee.coordinates.lng]}
//                 icon={customIcon}
//               ></Marker>
//             </Map>
//           </div>
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   )
// }

// export default CardMap

import React, { useContext, useState, useEffect } from "react"
import { Row, Col } from "reactstrap"

//swiper
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import "../../../../node_modules/swiper/swiper.scss"

import Leaflet from "leaflet"
import { Map, TileLayer, Marker } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"
import useColor from "hooks/useColors"
import classes from "../index.module.scss"
import { avatar } from "pages/data"

import SelectedEmployee from "pages/Tracking/context/selectedEmployee"
import { InfoWindow } from "google-maps-react"

const CardMap = props => {
  const { employees } = props
  const { colors } = useColor()
  const [coordinates, setCoordinates] = useState([])
  const { employeeId, changeSelectedEmployeeId } = useContext(SelectedEmployee)

  const customIcon = new Leaflet.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })

  useEffect(() => {
    setCoordinates([])
    if (employees) {
      setCoordinates(
        employees.map(employee => [
          employee.coordinates.lat,
          employee.coordinates.lng,
        ])
      )
    }
  }, [employees])

  return (
    <React.Fragment>
      <Swiper
        slidesPerView={1}
        spaceBetween={15}
        navigation
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1.25,
          },
          678: {
            slidesPerView: 2.25,
          },
          1000: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 2.5,
          },
          1400: {
            slidesPerView: 2.5,
          },
          1600: {
            slidesPerView: 3.5,
          },
          2000: {
            slidesPerView: 4.5,
          },
        }}
        loop={false}
        modules={[Pagination, Navigation]}
        className="owl-carousel owl-theme events navs-carousel px-3"
        id="team-carousel"
        dir="ltr"
        style={{ height: "73vh" }}
      >
        {employees.map((employee, index) => (
          <SwiperSlide
            key={index}
            className={`item d-flex align-items-center justify-content-center pt-3
      ${classes.employees_swiper}
    `}
            style={{
              transform:
                employeeId === employee.id ? "scale(1)" : "scale(0.97)",
              filter: employeeId
                ? employeeId === employee.id
                  ? "none"
                  : "blur(1.5px)"
                : "none",
              position: "relative",
            }}
            onClick={() => changeSelectedEmployeeId(employee.id)}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                borderRadius: "16px",
              }}
            >
              <Row
                className="px-4 py-3 align-items-center"
                style={{
                  backgroundColor:
                    employee.punchIn && employee.punchOut
                      ? colors.punchOut
                      : !employee.punchIn
                      ? colors.notPunchIn
                      : colors.punshIn,
                }}
              >
                <Col className="px-0" xs={4} style={{ position: "relative" }}>
                  <div className={classes.map_employee_list_image_background} />
                  <img
                    src={avatar}
                    className={classes.map_employee_list_image}
                  />
                </Col>
                <Col className="text-light text-center px-0 overflow-hidden">
                  <h1
                    className={`${classes.map_card_title} font-size-16`}
                    style={{ textTransform: "capitalize" }}
                  >
                    {employee.name}
                  </h1>
                  <Row className="pt-2 gap-1 font-size-12">
                    <span>{employee.jopTitle}</span>
                    <span>{employee.locationType}</span>
                  </Row>
                </Col>
              </Row>
              <div
                style={{
                  height: "60vh",
                  width: "100%",
                  padding: 0,
                  borderEndStartRadius: "16px",
                  borderEndEndRadius: "16px",
                  overflow: "hidden",
                }}
              >
                {coordinates.length ? (
                  <Map
                    center={coordinates[index]}
                    zoom={10}
                    style={{
                      // height: "calc(100% - 40px)",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                      position={[
                        employee.coordinates.lat,
                        employee.coordinates.lng,
                      ]}
                      icon={customIcon}
                    ></Marker>
                  </Map>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </React.Fragment>
  )
}

export default CardMap
