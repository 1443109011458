import React, { useMemo } from "react"
import { Card, CardBody, Col } from "reactstrap"

import TableContainer from "components/Common/TableContainer"
import useColor from "hooks/useColors"

import { attendanceDetailsDummyData } from "pages/data"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const AttendanceDetails = props => {
  const { activeLink } = useColor()

  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Full Name"),
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Punched In"),
        accessor: "punchedIn",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Punched Out"),
        accessor: "punchedOut",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Duration"),
        accessor: "duration",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Type"),
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Location"),
        accessor: "location",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
    ],
    [attendanceDetailsDummyData, props.t]
  )
  return (
    <Col md="12">
      <div className="mb-4 h4 card-title">
        {props.t("Latest Leave Transactions")}
      </div>
      <Card>
        <CardBody>
          <TableContainer
            columns={columns}
            data={attendanceDetailsDummyData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={5}
            allListSize={attendanceDetailsDummyData.length}
            activeLink={activeLink}
          />
        </CardBody>
      </Card>
    </Col>
  )
}

AttendanceDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AttendanceDetails))
