const trackingEmployees = [
    {
        id: 1,
        name: "ahmed mohamed ibrahim",
        jopTitle: "UI-UX",
        punchIn: "9:00 AM",
        punchOut: "12:00 PM",
        locationType: "destination",
        phone: "012345678912",
        coordinates: {
            "lat": 30.450129643196895,
            "lng": 31.136841662684088
        }
    },
    {
        id: 2,
        name: "ali mahmoud ahmed",
        jopTitle: "Front-End",
        punchIn: "9:00 AM",
        punchOut: null,
        locationType: "office",
        phone: "012345678912",
        coordinates: {
            "lat": 29.946600619112992,
            "lng": 32.5795946228014
        }
    },
    {
        id: 3,
        name: "shref mahmoud ali",
        jopTitle: "Back-End",
        punchIn: null,
        punchOut: null,
        locationType: null,
        phone: "012345678912",
        coordinates: {
            "lat": 31.279719933737034,
            "lng": 32.2719774353014
        }
    },
    {
        id: 4,
        name: "yassen mahmoud ahmed",
        jopTitle: "Mobile",
        punchIn: "9:00 AM",
        punchOut: null,
        locationType: "destination",
        phone: "012345678912",
        coordinates: {
            "lat": 31.016451307587744,
            "lng": 28.218022357176398
        }
    },
    {
        id: 5,
        name: "essam mahmoud ahmed",
        jopTitle: "Full-Stack",
        punchIn: "9:00 AM",
        punchOut: null,
        locationType: "destination",
        phone: "012345678912",
        coordinates: {
            "lat": 27.128808445677222,
            "lng": 31.184330950926398
        }
    },
    {
        id: 6,
        name: "hassan mahmoud ahmed",
        jopTitle: "DevOps",
        punchIn: "9:00 AM",
        punchOut: null,
        locationType: "destination",
        phone: "012345678912",
        coordinates: {
            "lat": 31.06351715628301,
            "lng": 31.382084857176398
        }
    },
];

const trackingEmployeesStatus = [
    "punchIn",
    "punchOut"
];

const typesOfAttendance = [
    "office",
    "online",
    "leave",
    "visit",
    "destination",
    "moving",
];

export {
    trackingEmployees,
    typesOfAttendance,
    trackingEmployeesStatus
};