import React, { useContext, useState, useEffect } from "react"
import classes from "../../index.module.scss"

import { Col, Input, Row } from "reactstrap"

import { avatar } from "pages/data"

import SelectedVacationType from "pages/Leave/VacationTypes/context/selectedVacationTypes"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Add = props => {
  const { toggle } = props
  const {
    emplyeesList,
    setSelecteEmployees,
    selectedEmplyeesList,
    selectedVacationTypesList,
    specificVacationType,
  } = useContext(SelectedVacationType)
  const [balance, setBalance] = useState(
    specificVacationType ? [specificVacationType] : selectedVacationTypesList
  )

  return (
    <>
      <div
        style={{ maxHeight: "60vh", width: "100%" }}
        className={`${classes.hideScrollBar} py-4 overflow-y-auto`}
      >
        <Row
          className={`${classes.employee_list_container_wrapper} mx-2 flex-column`}
        >
          {emplyeesList.map((employee, index) => (
            <Row
              key={index}
              className={` 
                    ${classes.employee_list_container}
                   p-2 p-xl-3`}
              style={{
                border: "1px solid transparent",
              }}
            >
              <div className={classes.checkbox_Container}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedEmplyeesList.find(
                    emp => emp.id === employee.id
                  )}
                  onClick={() => setSelecteEmployees(employee)}
                />
              </div>
              <Col
                xs={3}
                md={12}
                xl={3}
                className="d-flex align-items-center justify-content-center"
                style={{ position: "relative" }}
              >
                <div
                  className={classes.employee_list_image_background}
                  style={{
                    backgroundColor: "gray",
                  }}
                />
                <img src={avatar} className={classes.employee_list_image} />
              </Col>
              <Col className="px-0">
                <h1 className={classes.employee_name}>{employee.name}</h1>
                {/* <Row className="gap-1 font-size-12"> */}
                <span>{employee.jopTitle}</span>
                {balance &&
                  balance.map((one, index) => (
                    <Row
                      key={index}
                      className="gap-1 justify-content-start align-items-center"
                    >
                      <div className="d-flex gap-3 justify-content-between align-items-center">
                        <span>{one.name}:</span>
                        <Col xs="4">
                          <Input
                            name="balance"
                            type="number"
                            placeholder={props.t(
                              "Insert The Period Before Taking Leave"
                            )}
                            className="py-1 text-center"
                            value={one.balance}
                          />
                        </Col>
                      </div>
                      <Col></Col>
                    </Row>
                  ))}
                {/* </Row> */}
              </Col>
            </Row>
          ))}
        </Row>
      </div>
      <Row className="mt-4">
        <Col>
          <div className="d-flex gap-2">
            <button type="submit" className={`btn btn-primary save-customer`}>
              {props.t("Add Beneficiaries")}
            </button>
            <button
              onClick={toggle}
              type="button"
              className="btn btn-cancel text-primary"
            >
              {props.t("Cancel")}
            </button>
          </div>
        </Col>
      </Row>
    </>
  )
}

Add.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Add))
