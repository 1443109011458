import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const ActivityComp = props => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">{props.t("Recent Activity")}</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-up-arrow-circle font-size-18 text-danger" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    22 Nov 2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>
                    “Ahmed Mohamed” - request “sick” leave - from “ 22/01 /2024”
                    to “24 / 01 /2024” ...{" "}
                    <span className="text-primary font-size-14">Read More</span>{" "}
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-left-arrow-circle font-size-18 text-warning" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    02:00 PM{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>
                    “Ahmed Mohamed” - punched out - “office“ - “portsaid“ / 4 H
                    ...{" "}
                    <span className="text-primary font-size-14">Read More</span>{" "}
                  </div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                {/* <i className="bx bxs-right-arrow-circle text-primary font-size-18" /> */}
                <i className="bx bxs-right-arrow-circle text-primary font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    10:00 AM{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>“ahmed mohamed“- punched in - “office“ - “portsaid“</div>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-x-circle font-size-18 text-danger" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    22 Nov 2024{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>
                    “Ahmed Mohamed” - request “sick” leave - from “ 22/01 /2024”
                    to “24 / 01 /2024” was refused ...{" "}
                    <span className="text-primary font-size-14">Read More</span>{" "}
                  </div>
                </div>
              </div>
            </li>

            {/* <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    15 Nov{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Joined the group “Boardsmanship Forum”</div>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="me-3">
                  <h5 className="font-size-14">
                    22 Nov{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div>Responded to need “In-Kind Opportunity”</div>
                </div>
              </div>
            </li> */}
          </ul>
          <div className="text-center mt-4">
            <Link
              to="#"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              {props.t("View More")} <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ActivityComp.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ActivityComp))
