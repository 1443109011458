import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
  FormGroup,
  Button,
} from "reactstrap"
import Select from "react-select"
import { assignedAdminDummyData, assignedStaffData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const EditAssignment = props => {
  const { modal, toggle, location } = props
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState(null)
  const [selectedAssignedStaff, setSelectedAssignedStaff] = useState(null)

  // update initail values
  useEffect(() => {
    if (!modal) return
    setSelectedAssignedAdmin(
      location && location.assignedAdmin
        ? {
            value: location.assignedAdmin,
            label: location.assignedAdmin,
          }
        : null
    )
    setSelectedAssignedStaff(
      location && location.assignedStaff
        ? {
            value: location.assignedStaff,
            label: location.assignedStaff,
          }
        : null
    )
  }, [location])

  const submitHandler = e => {
    e.preventDefault()
    // TODO: submit logic && checks
    console.log("submitted")
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Edit Assignment")}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <Row>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-sm-2">
                {props.t("Assigned Admin")}
              </Label>
              <Col sm="10">
                <Row className="align-items-center">
                  <Col>
                    <Select
                      value={selectedAssignedAdmin}
                      onChange={selectedOption => {
                        setSelectedAssignedAdmin(selectedOption)
                      }}
                      options={assignedAdminDummyData.map(one => ({
                        value: one,
                        label: one,
                      }))}
                      isMulti={true}
                      className="select2-selection"
                    />
                  </Col>
                  <Col xs="1">
                    <div className="col-sm-2 d-flex align-items-center">
                      <Button
                        color="primary"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        className="bg-primary font-size-24 text-center pt-3 d-flex align-items-center justify-content-center"
                      >
                        <i className="dripicons-plus p-0 text-white"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-sm-2">
                {props.t("Assigned Staff (S)")}
              </Label>
              <Col sm="10">
                <Row className="align-items-center">
                  <Col>
                    <Select
                      value={selectedAssignedStaff}
                      onChange={selectedOption => {
                        setSelectedAssignedStaff(selectedOption)
                      }}
                      options={assignedStaffData.map(one => ({
                        value: one,
                        label: one,
                      }))}
                      isMulti={true}
                      className="select2-selection"
                    />
                  </Col>
                  <Col xs="1">
                    <div className="col-sm-2 d-flex align-items-center">
                      <Button
                        color="primary"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                        }}
                        className="bg-primary font-size-24 text-center pt-3 d-flex align-items-center justify-content-center"
                      >
                        <i className="dripicons-plus p-0 text-white"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Row>
          <Row className="mt-5">
            <Col sm="2" />
            <Col>
              <div className="d-flex gap-2">
                <button type="submit" className={`btn btn-primary`}>
                  {props.t("Save Changes")}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

EditAssignment.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(EditAssignment))
