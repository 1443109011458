import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const SocialSource = props => {
  const socials = [
    {
      title: "location 1",
      bgColor: "bg-primary",
      iconClass: "mdi-map-marker",
      description: "20 office",
    },
    {
      title: "location 2",
      bgColor: "bg-primaryThree",
      iconClass: "mdi-map-marker",
      description: "7 online",
    },
    {
      title: "location 3",
      bgColor: "bg-primary bg-soft",
      iconClass: "mdi-map-marker",
      description: "3 visit",
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            {props.t("Top Working Hours / Location")}
          </CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              {/* <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24"> */}
              <span className="avatar-title rounded-circle bg-primary font-size-24">
                <i className="mdi mdi-map-marker "></i>
              </span>
            </div>
            <p className="font-16 mb-2"></p>
            <h5>
              <Link to="#" className="text-dark">
                location 1 - <span className=" font-16">20 office</span>{" "}
              </Link>
            </h5>
            <p className="">location 1 office most punch in this month.</p>
            <Link to="#" className="text-primary font-16">
              {props.t("Learn more")} <i className="mdi mdi-chevron-right"></i>
            </Link>
          </div>
          <Row className="mt-4">
            {socials.map((social, key) => (
              <Col className="col-4" key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        social.bgColor +
                        " font-size-16"
                      }
                    >
                      <i
                        className={"mdi " + social.iconClass + " text-white"}
                      ></i>
                    </span>
                  </div>
                  <h5 className="font-size-15">{social.title}</h5>
                  <p className=" mb-0">{social.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

SocialSource.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SocialSource))
