import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import AttendanceDetails from "pages/Dashboard/AttendanceDetails"
import Attendance from "pages/Dashboard/transactions/Attendance"
import Leave from "pages/Dashboard/transactions/leave"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { yearDummyData, weekDummyData, monthDummyData } from "pages/data"

const Dashboard = props => {
  document.title = props.t("Smart Village | Dashboard")
  const [subscribemodal, setSubscribemodal] = useState(false)

  const reports = [
    { title: "Attendance", iconClass: "bx-copy-alt", description: "20" },
    { title: "Leave", iconClass: "bx-archive-in", description: "5" },
    {
      title: "Not Attend Yet",
      iconClass: "bx-purchase-tag-alt",
      description: "5",
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    if (periodType === "yearly") {
      setPeriodData(yearDummyData)
    } else if (periodType === "monthly") {
      setPeriodData(monthDummyData)
    } else if (periodType === "weekly") {
      setPeriodData(weekDummyData)
    }
  }, [periodType])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="fw-medium">{props.t(report.title)}</p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div
                            className={`avatar-sm rounded-circle align-self-center mini-stat-icon bg-primary`}
                          >
                            <span
                              className={`avatar-title rounded-circle ${
                                key === 0
                                  ? "bg-primary"
                                  : key === 1
                                  ? "bg-primaryThree"
                                  : "bg-primaryTwo"
                              }`}
                            >
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">
                      {props.t("Attendance Type WH")}
                    </h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly")
                            }}
                            id="one_month"
                          >
                            {props.t("Week")}
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly")
                            }}
                            id="one_month"
                          >
                            {props.t("Month")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly")
                            }}
                            id="one_month"
                          >
                            {props.t("Year")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StackedColumnChart
                    periodData={periodData}
                    dataColors='["--bs-primary", "--bs-primaryThree", "--bs-primaryTwo", "--bs-primary-50", "--bs-primaryThree-50", "--bs-primaryTwo-50"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <AttendanceDetails />
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Attendance />
            </Col>
            <Col xl="6">
              <Leave />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Dashboard))
