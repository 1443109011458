import React, { useState } from "react"
import { Row, Col, Label, Input, FormFeedback } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import DatePicker from "@deskpro/react-datepicker-hijri"
import moment from "moment-hijri"
import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const GregorianHijriDate = props => {
  const { label } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      gregorianDate: "", // Initialize gregorianDate in the form state
    },
    validationSchema: Yup.object({
      gregorianDate: Yup.string().required(props.t("Please Enter Date From")),
    }),
    onSubmit: values => {
      // TODO: submit values
      toggle()
    },
  })

  const [changeBoth, setChangeBoth] = useState(true)
  const [selectedHijriDate, setSelectedHijriDate] = useState(null)
  const [selectedGregorianDate, setSelectedGregorianDate] = useState(null)

  const handleHijriChange = date => {
    setSelectedHijriDate(date)
    if (changeBoth) {
      const gregorianDate = moment(
        date.format("iYYYY/iM/iD"),
        "iYYYY/iM/iD"
      ).toDate()
      setSelectedGregorianDate(gregorianDate)
      validation.setFieldValue("gregorianDate", gregorianDate) // Update gregorianDate in the form state
    }
  }

  const handleGregorianChange = e => {
    const gregorianDate = moment(e.target.value, "YYYY-MM-DD").toDate()
    setSelectedGregorianDate(gregorianDate)
    if (changeBoth) {
      const hijriDate = moment(gregorianDate).format("iYYYY/iM/iD")
      setSelectedHijriDate(moment(hijriDate, "iYYYY/iM/iD"))
    }
  }

  return (
    <div className="mb-3">
      <Row>
        <Col>
          <Label className="form-label">
            {label} ({props.t("Gregorian")})
          </Label>
          <Input
            name="gregorianDate"
            type="date"
            onChange={handleGregorianChange}
            onBlur={validation.handleBlur}
            value={
              selectedGregorianDate
                ? moment(selectedGregorianDate).format("YYYY-MM-DD")
                : ""
            }
            invalid={
              validation.touched.gregorianDate &&
              validation.errors.gregorianDate
                ? true
                : false
            }
          />
          {validation.touched.gregorianDate &&
          validation.errors.gregorianDate ? (
            <FormFeedback type="invalid">
              {validation.errors.gregorianDate}
            </FormFeedback>
          ) : null}
        </Col>
        <Col>
          <Label className="form-label">
            {label} ({props.t("Hijri")})
          </Label>
          <div style={{ position: "relative" }}>
            <DatePicker
              className={`form-control`}
              calendar="hijri"
              locale="en"
              value={
                selectedHijriDate ? selectedHijriDate.format("iYYYY/iM/iD") : ""
              }
              onChange={handleHijriChange}
            />
          </div>
        </Col>

        <Col sm="3" className="align-self-center">
          <div className="form-check form-switch pt-4">
            <Label className="form-label">{props.t("Change Both")}</Label>
            <input
              type="checkbox"
              className="form-check-input"
              id="specificReason"
              checked={changeBoth}
              onChange={() => setChangeBoth(prevState => !prevState)}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

GregorianHijriDate.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(GregorianHijriDate))
