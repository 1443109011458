import React from "react"
import { Button } from "reactstrap"
import { useNavigate, useLocation } from "react-router-dom"
import classes from "./index.module.scss"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Navigation = props => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const type = new URLSearchParams(search).get("type")

  return (
    <nav className={classes.nav_container}>
      <ul className="d-flex align-items-center list-unstyled justify-content-center gap-2">
        <li>
          <Button
            type="button"
            onClick={() => navigate("?type=edit-geo")}
            color={type === "edit-geo" ? "light" : "primary"}
            outline={type === "edit-geo" ? false : true}
            className={`btn btn-${
              type === "edit-geo" ? "primary" : "light"
            } bg-${
              type === "edit-geo" ? "primary" : "light"
            } d-flex align-items-center`}
          >
            <i className="bx bx-edit font-size-16 me-1 py-1" />{" "}
            {props.t("Edit")}
          </Button>
        </li>
        <li>
          <Button
            type="button"
            onClick={() => navigate("?type=fencing-geo")}
            color={type === "fencing-geo" ? "light" : "primary"}
            outline={type === "fencing-geo" ? false : true}
            className={`btn btn-${
              type === "fencing-geo" ? "primary" : "light"
            } bg-${
              type === "fencing-geo" ? "primary" : "light"
            } d-flex align-items-center`}
          >
            <i className="mdi mdi-map-marker-radius font-size-16 me-1" />{" "}
            {props.t("Fencing")}
          </Button>
        </li>
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navigation))
