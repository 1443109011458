import React, { useContext, useState } from "react"
import { Card, Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import VacationTypeList from "pages/Leave/VacationTypes/VacationTypeList"
import CreateEditVacationType from "pages/Leave/VacationTypes/CreateEditVacationType"

import SelectedVacationType, {
  SelectedVacationTypeProvider,
} from "pages/Leave/VacationTypes/context/selectedVacationTypes"
import BeneficiariesModel from "pages/Leave/VacationTypes/BeneficiariesModel/BeneficiariesModel"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const VacationTypes = withRouter(
  withTranslation()(props => {
    //meta title
    document.title = props.t("Vacation Types | Smart Village")
    const { selectedVacationTypesList, toggleSpecificVacationType } =
      useContext(SelectedVacationType)
    const [beneficiariesModel, setBeneficiariesModel] = useState(false)
    const [vacationTypeModel, setVacationTypeModel] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [vacationType, setVacationType] = useState(null)

    const toggleCreateEditVacationTypeModel = () => {
      if (vacationTypeModel) setVacationType(null)
      setVacationTypeModel(prevState => !prevState)
    }

    const toggleBeneficiariesModel = () =>
      setBeneficiariesModel(prevState => !prevState)

    const toggleBeneficiariesModelAndRemoveSpecificVacationType = () => {
      toggleSpecificVacationType(null)
      setBeneficiariesModel(prevState => !prevState)
    }

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Leave")}
            breadcrumbItem={props.t("Vacation Types")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <div
                  className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
                  style={{ marginRight: "25px" }}
                >
                  <Button
                    color="primary"
                    className="btn-rounded"
                    onClick={() => {
                      setIsEdit(false)
                      toggleCreateEditVacationTypeModel()
                    }}
                  >
                    <i className="mdi mdi-plus" /> {props.t("New leave type")}
                  </Button>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button
                      color="danger"
                      className="btn-rounded"
                      disabled={!selectedVacationTypesList.length}
                    >
                      <i className="mdi mdi-trash-can-outline" />{" "}
                      {props.t("Deactivate Selected")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedVacationTypesList.length})
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button
                      color={
                        !selectedVacationTypesList.length
                          ? "secondary"
                          : "warning"
                      }
                      className="btn-rounded"
                      onClick={
                        toggleBeneficiariesModelAndRemoveSpecificVacationType
                      }
                      disabled={!selectedVacationTypesList.length}
                    >
                      <i className="fa fas fa-user-friends" />{" "}
                      {props.t("Beneficiaries")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedVacationTypesList.length})
                    </span>
                  </div>
                </div>

                <VacationTypeList
                  toggleCreateEditVacationTypeModel={
                    toggleCreateEditVacationTypeModel
                  }
                  setIsEdit={setIsEdit}
                  setVacationType={setVacationType}
                  toggleBeneficiariesModel={toggleBeneficiariesModel}
                />

                <BeneficiariesModel
                  modal={beneficiariesModel}
                  toggle={toggleBeneficiariesModel}
                />

                <CreateEditVacationType
                  modal={vacationTypeModel}
                  toggle={toggleCreateEditVacationTypeModel}
                  isEdit={isEdit}
                  vacationType={vacationType}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  })
)

VacationTypes.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const Index = () => {
  return (
    <SelectedVacationTypeProvider>
      <VacationTypes />
    </SelectedVacationTypeProvider>
  )
}

export default Index
