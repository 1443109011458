import React, { useState, useContext, useEffect } from "react"
import Leaflet from "leaflet"
import { Map, TileLayer, Marker } from "react-leaflet"
import { avatar } from "pages/data"
import "../../../../node_modules/leaflet/dist/leaflet.css"
import "./map.css"
import useColor from "hooks/useColors"
import SelectedEmployee from "pages/Tracking/context/selectedEmployee"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"
delete Leaflet.Icon.Default.prototype._getIconUrl

const SimpleMap = props => {
  const { employees } = props
  const { colors } = useColor()
  const { employeeId, changeSelectedEmployeeId } = useContext(SelectedEmployee)

  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [state, setState] = useState({
    lat: 51.505,
    lng: -0.09,
    zoom: 2,
  })

  useEffect(() => {
    if (employeeId) {
      const selected = employees.find(employee => employee.id === employeeId)
      setSelectedEmployee(selected)
    } else {
      setSelectedEmployee(null)
    }
  }, [employeeId, employees])

  const position = [state.lat, state.lng]

  return (
    <Map
      center={
        employeeId && selectedEmployee && selectedEmployee.coordinates
          ? [selectedEmployee.coordinates.lat, selectedEmployee.coordinates.lng]
          : position
      }
      zoom={employeeId ? 8 : state.zoom}
      style={{ height: "73vh", borderRadius: "16px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {employees
        ? employees.map((employee, index) => (
            <Marker
              key={index}
              onClick={() => changeSelectedEmployeeId(employee.id)}
              position={[employee.coordinates.lat, employee.coordinates.lng]}
              icon={
                new Leaflet.Icon({
                  iconUrl: avatar,
                  iconSize: [32, 32],
                  iconAnchor: [16, 32],
                  popupAnchor: [0, -32],
                  className:
                    employee.id === employeeId
                      ? employee.punchOut
                        ? "map_image_active map_image_active_punchOut"
                        : employee.punchIn
                        ? "map_image_active map_image_active_punshIn"
                        : "map_image_active map_image_active_notPunchIn"
                      : "map_image",
                })
              }
            />
          ))
        : null}
    </Map>
  )
}

export default SimpleMap
// https://leaflet-extras.github.io/leaflet-providers/preview/
