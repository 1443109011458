import React, { useMemo } from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import useColor from "hooks/useColors"
import { locationDummyData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const LocationList = props => {
  const {
    setLocation,
    setEditLocationInfoModal,
    setEditAssignmentModel,
    setGeoModal,
    selectedLocation,
    setSelectedLocation,
  } = props
  const { activeLink } = useColor()

  const handleLocationClick = arg => {
    const location = arg
    setLocation({
      nameOfLocation: location.nameOfLocation,
      attendanceType: location.attendanceType,
      locationType: location.locationType,
      assignedAdmin: location.assignedAdmin,
      assignedStaff: location.assignedStaff,
    })
  }

  const getCheckedLocationHandler = locationData => {
    const emplyeeExist = selectedLocation.find(
      employee => employee.id === locationData.id
    )
    if (emplyeeExist) {
      setSelectedLocation(prevState =>
        prevState.filter(location => location.id !== locationData.id)
      )
    } else {
      setSelectedLocation(prevState => [...prevState, locationData])
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return (
            <input
              onChange={() => getCheckedLocationHandler(cellProps.row.original)}
              checked={selectedLocation.find(
                location => location.id === cellProps.row.original.id
              )}
              type="checkbox"
              className="form-check-input"
            />
          )
        },
      },
      {
        Header: props.t("Name Of Location"),
        accessor: "nameOfLocation",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Attendance Type (s)"),
        accessor: "attendanceType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Location Type"),
        accessor: "locationType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Geo"),
        accessor: "geo",
        filterable: true,
        Cell: cellProps => {
          const locationData = cellProps.row.original
          return (
            <span
              style={{ cursor: "pointer" }}
              className="text-center text-primary"
              onClick={() => {
                handleLocationClick(locationData)
                setGeoModal(true)
              }}
            >
              <i className="dripicons-location text-primary"></i>
            </span>
          )
        },
      },
      {
        Header: props.t("Assigned Admin"),
        accessor: "assignedAdmin",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Assigned Staff"),
        accessor: "assignedStaff",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-bell-ring font-size-16 text-danger me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Deactivate")}</span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Deactivate")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-eye-off font-size-16 text-danger me-1"
                    id="hidetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Hide")}</span>
                  <UncontrolledTooltip placement="top" target="hidetooltip">
                    {props.t("Hide")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const locationData = cellProps.row.original
                    handleLocationClick(locationData)
                    setEditLocationInfoModal(true)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 text-primary me-1"
                    id="editLocationInformationtooltip"
                  ></i>
                  <span className="text-primary">
                    {props.t("Edit Location Information")}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target="editLocationInformationtooltip"
                  >
                    {props.t("Edit Location Information")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const locationData = cellProps.row.original
                    handleLocationClick(locationData)
                    setEditAssignmentModel(true)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 text-primary me-1"
                    id="editAssignmenttooltip"
                  ></i>
                  <span className="text-primary">
                    {props.t("Edit Assignment")}
                  </span>
                  <UncontrolledTooltip
                    placement="top"
                    target="editAssignmenttooltip"
                  >
                    {props.t("Edit Assignment")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [selectedLocation, props.t]
  )

  return (
    <TableContainer
      columns={columns}
      data={locationDummyData}
      isGlobalFilter={true}
      isAddOptions={false}
      customPageSize={5}
      allListSize={locationDummyData.length}
      activeLink={activeLink}
    />
  )
}

LocationList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LocationList))
