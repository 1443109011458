import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

const useColor = (props) => {
    const Layout = useSelector((state) => state.Layout);
    const [ActiveColor, setActiveColor] = useState({
        mainColor: "",
        activeLink: ""
    });

    useEffect(() => {
        if (Layout.leftSideBarTheme === "colored") {
            setActiveColor({
                mainColor: "primary", //green
                activeLink: "primaryThree"
            });
        } else if (Layout.leftSideBarTheme === "red") {
            setActiveColor({
                mainColor: "primaryTwo",
                activeLink: "primary"
            });
        } else if (Layout.leftSideBarTheme === "yellow") {
            setActiveColor({
                mainColor: "primaryThree",
                activeLink: "primaryTwo"
            });
        } else {
            setActiveColor({
                mainColor: "primary",
                activeLink: "primary"
            });
        }
    }, [Layout]);

    return {
        mainColor: ActiveColor.mainColor,
        activeLink: ActiveColor.activeLink,
        colors: {
            punshIn: "#014421",
            punchOut: "#D3AF37",
            notPunchIn: "#800000",
        }
    };
};

export default useColor;
