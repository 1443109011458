import React, { useState, useContext } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import HeaderForm from "pages/Tracking/headerForm"
import EmployeeList from "pages/Tracking/employeeList"
import classes from "./index.module.scss"
import GridMap from "pages/Tracking/maps/gridMap"
import MapMap from "pages/Tracking/maps/map"
import CardMap from "pages/Tracking/maps/cardMap"
import { SelectedEmployeeProvider } from "pages/Tracking/context/selectedEmployee"
import SelectedEmployee from "pages/Tracking/context/selectedEmployee"

import { trackingEmployees } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Tracking = withRouter(
  withTranslation()(props => {
    document.title = props.t("Tracking | Smart Village")

    const [layoutType, setLayoutType] = useState("grid")
    const { selectedEmployee } = useContext(SelectedEmployee)

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Tracking List")}
            breadcrumbItem={props.t("Tracking")}
          />
          <Card className="p-3">
            <Row className="pb-3 justify-content-center">
              <HeaderForm
                layoutType={layoutType}
                setLayoutType={setLayoutType}
              />
            </Row>
            <Row className="px-2 gap-3">
              <Col
                md={3}
                xl={4}
                style={{ height: "100%", height: "73vh" }}
                className={`${classes.hideScrollBar} overflow-y-auto d-none d-md-block`}
              >
                <EmployeeList employees={trackingEmployees} />
              </Col>
              <Col
                className="px-0"
                style={{ width: "100%", overflow: "hidden", height: "73vh" }}
              >
                {layoutType === "grid" ? (
                  <GridMap selectedEmployee={selectedEmployee} />
                ) : layoutType === "map" ? (
                  <MapMap employees={trackingEmployees} />
                ) : (
                  <CardMap employees={trackingEmployees} />
                )}
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    )
  })
)

Tracking.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const Index = () => {
  return (
    <SelectedEmployeeProvider>
      <Tracking />
    </SelectedEmployeeProvider>
  )
}

export default Index
