import React, { useContext, useState } from "react"
import { Card, Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import LeaveListTabel from "pages/Leave/LeaveList/LeaveListTabel"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const LeaveList = props => {
  document.title = props.t("Leave List | Smart Village")

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={props.t("Leave")}
          breadcrumbItem={props.t("Leave List")}
        />
        <Row>
          <Col xs="12">
            <Card>
              {/* <div
                className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
                style={{ marginRight: "25px" }}
              >
                <Button color="primary" className="btn-rounded">
                  <i className="mdi mdi-plus" /> New leave type
                </Button>
                <div className="d-flex flex-column align-items-center gap-2">
                  <Button color="danger" className="btn-rounded">
                    <i className="mdi mdi-trash-can-outline" /> Deactivate
                    Selected
                  </Button>
                  <span className="me-2">Selected ({"0"})</span>
                </div>
                <div className="d-flex flex-column align-items-center gap-2">
                  <Button color={"warning"} className="btn-rounded">
                    <i className="fa fas fa-user-friends" /> Beneficiaries
                  </Button>
                  <span className="me-2">Selected ({"0"})</span>
                </div>
              </div> */}

              <LeaveListTabel />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

LeaveList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LeaveList))
