import React, { useMemo } from "react"
import { Card, CardBody } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { TabelInfo } from "components/shared"
import { AttendaceTransactionDummyData } from "pages/data"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Attendance = props => {
  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Location"),
        accessor: "location",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: (
          <TabelInfo
            header={props.t("WH")}
            id="wh"
            info={props.t("Served working hours throughout the mentioned date")}
          />
        ),
        accessor: "workHours",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("No.Staff"),
        accessor: "staffNumber",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Types"),
        accessor: "types",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Top Staff"),
        accessor: "topStaff",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
    ],
    [AttendaceTransactionDummyData, props.t]
  )
  return (
    <>
      <div className="mb-4 h4 card-title">{props.t("Attendance Details")}</div>
      <Card>
        <CardBody>
          <TableContainer
            columns={columns}
            data={AttendaceTransactionDummyData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={5}
            allListSize={AttendaceTransactionDummyData.length}
          />
        </CardBody>
      </Card>
    </>
  )
}

Attendance.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Attendance))
