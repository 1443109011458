import React from "react"

import Or from "./Or"
import Add from "./Add"
import And from "./And"

import { useLocation } from "react-router-dom"

const EmployeesList = ({ toggle }) => {
  const location = useLocation()
  const type = new URLSearchParams(location.search).get("type")

  if (!type || type === "or") {
    return <Or toggle={toggle} />
  } else if (type === "and") {
    return <And toggle={toggle} />
  } else if (type === "add") {
    return <Add toggle={toggle} />
  }
}

export default EmployeesList
