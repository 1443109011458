import React, { useMemo } from "react"
import {
  CardBody,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import TableContainer from "components/Common/TableContainer"
import { BadgeApp } from "components/shared"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

import { leaveListDummyData } from "pages/data"

const LeaveListTabel = props => {
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Balance"),
        accessor: "balance",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Date"),
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Leave Period"),
        accessor: "leavePeriod",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return (
            <BadgeApp
              color={
                cellProps.value === "approved"
                  ? "success"
                  : cellProps.value === "pending"
                  ? "warning"
                  : "danger"
              }
              content={cellProps.value}
            />
          )
        },
      },
      {
        Header: props.t("Reasons"),
        accessor: "reasons",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Number Of Days"),
        accessor: "numberOfDays",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Employee Name"),
        accessor: "employeeName",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-lightning-bolt font-size-16 me-1"
                    id="deactivetooltip"
                  ></i>
                  <span>{props.t("Take Action")}</span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Take Action")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [leaveListDummyData, props.t]
  )

  return (
    <CardBody>
      <TableContainer
        columns={columns}
        data={leaveListDummyData}
        isGlobalFilter={true}
        isAddOptions={false}
        customPageSize={5}
        allListSize={leaveListDummyData.length}
      />
    </CardBody>
  )
}

LeaveListTabel.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LeaveListTabel))
