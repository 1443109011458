import React, { createContext, useState, useEffect } from "react"
import { vacationTypesDummyData, BeneficiariesEmployees } from "pages/data"

const initialSelectedVacationType = []
const initialSelectedSelectedEmployees = []
const initialSpecificVacationType = null

const SelectedVacationType = createContext({
  vacationTypes: [],
  selectedVacationTypesList: initialSelectedVacationType,
  toggleSelectedVacationTypes: vacationTypeData => {},

  // specific vacation type
  specificVacationType: initialSpecificVacationType,
  toggleSpecificVacationType: vacationType => {},

  // BeneficiariesModel
  emplyeesList: [],
  selectedEmplyeesList: [],
  setSelecteEmployees: employeeData => {},
  setSelecteAllEmployees: () => {},
})

export const SelectedVacationTypeProvider = props => {
  const { children } = props

  const [vacationTypes, setVacationTypes] = useState(vacationTypesDummyData)
  const [selectedVacationTypesList, setSelectedVacationTypesList] = useState(
    initialSelectedVacationType
  )

  // specific vacation type
  const [specificVacationType, setSpecificVacationType] = useState(
    initialSpecificVacationType
  )

  // BeneficiariesModel
  const [emplyeesList, setEmplyeesList] = useState(BeneficiariesEmployees)
  const [selectedEmplyeesList, setSelectedEmplyeesList] = useState(
    initialSelectedSelectedEmployees
  )

  const toggleSelectedVacationTypes = vacationTypeData => {
    let list
    setSelectedVacationTypesList(prevState => {
      list = prevState
      return prevState
    })
    const vacationTypeExist = list.find(one => one.id === vacationTypeData.id)
    if (vacationTypeExist) {
      setSelectedVacationTypesList(prevState =>
        prevState.filter(
          vacationType => vacationType.id !== vacationTypeData.id
        )
      )
    } else {
      setSelectedVacationTypesList(prevState => [
        ...prevState,
        vacationTypeData,
      ])
    }
  }

  const toggleSpecificVacationType = vacationType =>
    setSpecificVacationType(vacationType)

  const setSelecteEmployees = employeeData => {
    const employeeExist = selectedEmplyeesList.find(
      employee => employee.id === employeeData.id
    )
    if (employeeExist) {
      setSelectedEmplyeesList(prevState =>
        prevState.filter(employee => employee.id !== employeeData.id)
      )
    } else {
      setSelectedEmplyeesList(prevState => [...prevState, employeeData])
    }
  }

  const setSelecteAllEmployees = () => {
    if (selectedEmplyeesList.length === emplyeesList.length) {
      setSelectedEmplyeesList([])
    } else {
      setSelectedEmplyeesList(BeneficiariesEmployees)
    }
  }

  const data = {
    vacationTypes,
    selectedVacationTypesList,
    toggleSelectedVacationTypes,

    // specific vacation type
    specificVacationType,
    toggleSpecificVacationType,

    // BeneficiariesModel
    emplyeesList,
    selectedEmplyeesList,
    setSelecteEmployees,
    setSelecteAllEmployees,
  }

  return (
    <SelectedVacationType.Provider value={data}>
      {children}
    </SelectedVacationType.Provider>
  )
}

export default SelectedVacationType
