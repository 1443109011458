import React from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Card,
  Button,
} from "reactstrap"

import classes from "./index.module.scss"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const ManagementLeave = props => {
  const { modal, toggle, avatar, employee } = props
  if (!employee) return null

  return (
    <Modal isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Management Leave")}
      </ModalHeader>
      <ModalBody>
        <Row className="my-3">
          <Col
            xs={12}
            className="d-flex gap-3 flex-column align-items-center justify-content-center"
          >
            <img
              src={avatar}
              style={{ width: "96px", height: "96px", borderRadius: "50%" }}
            />
            <h1 style={{ fontSize: "16px", textTransform: "capitalize" }}>
              {employee.username}
            </h1>
          </Col>
        </Row>
        <Row
          className={`py-3 ${classes.hideScrollBar}`}
          style={{ height: "60vh", overflowY: "auto" }}
        >
          <Col xs={12}>
            <Card
              className="p-3 py-4 shadow-lg"
              style={{ borderRadius: "16px" }}
            >
              <h1 style={{ fontSize: "16px", textTransform: "capitalize" }}>
                {props.t("Casual")}
              </h1>
              <Row className="py-2 flex-column gap-2">
                <Col>
                  <span>{props.t("Balance")} : </span>
                  <span>10</span>
                </Col>
                <Col>
                  <span>{props.t("Date")} : </span>
                  <span>01/05/2024</span>
                </Col>
                <Col>
                  <span>{props.t("Leave Period")} : </span>
                  <span>01/05/2024 {props.t("To")} 05/05/2024</span>
                </Col>
                <Col>
                  <span>{props.t("Number Of Days")} : </span>
                  <span>4 {props.t("Days")}</span>
                </Col>
                <Col>
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <div className="d-flex gap-1 justify-content-start">
                      <span>{props.t("Reason")}</span>
                      <span>:</span>
                    </div>
                    <Input
                      name="balance"
                      type="text"
                      placeholder={props.t("Insert The Reason")}
                      className="py-1"
                    />
                  </div>
                </Col>

                <div className="px-4 mt-3">
                  <Row className="justify-content-between align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      style={{
                        width: "48%",
                        marginRight: "2%",
                        borderRadius: "8px",
                      }}
                    >
                      {props.t("Approve")}
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      style={{
                        width: "48%",
                        marginLeft: "2%",
                        borderRadius: "8px",
                      }}
                    >
                      {props.t("Reject")}
                    </Button>
                  </Row>
                </div>
              </Row>
            </Card>
          </Col>

          {[
            { name: "Holidays", type: "Approved", reason: "" },
            { name: "Sick", type: "Approved", reason: "" },
            {
              name: "Casual",
              type: "Rejected",
              reason: "We Have Work Pressure",
            },
          ].map((one, index) => (
            <Col xs={12} key={index}>
              <Card
                className="p-3 py-4 shadow-lg"
                style={{ borderRadius: "16px" }}
              >
                <h1 style={{ fontSize: "16px", textTransform: "capitalize" }}>
                  {one.name}
                </h1>
                <Row className="py-2 flex-column gap-2">
                  <Col>
                    <span>{props.t("Balance")} : </span>
                    <span>10</span>
                  </Col>
                  <Col>
                    <span>{props.t("Date")} : </span>
                    <span>01/05/2024</span>
                  </Col>
                  <Col>
                    <span>{props.t("Leave Period")} : </span>
                    <span>01/05/2024 {props.t("To")} 05/05/2024</span>
                  </Col>
                  <Col>
                    <span>{props.t("Number Of Days")} : </span>
                    <span>4 {props.t("Days")}</span>
                  </Col>
                  <Col>
                    <span>{props.t("Reason")} : </span>
                    <span>{one.reason}</span>
                  </Col>

                  <div className="px-4 mt-3">
                    <Row className="justify-content-between align-items-center">
                      <Button
                        type="submit"
                        outline
                        color={one.type === "Approved" ? "primary" : "danger"}
                        style={{
                          borderRadius: "8px",
                        }}
                        disabled
                      >
                        {one.type}
                      </Button>
                    </Row>
                  </div>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  )
}

ManagementLeave.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ManagementLeave))
