const attendanceDetailsDummyData = [
    {
        id: 1,
        date: "22/01/2024",
        fullName: "Stephen Rash",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 2,
        date: "22/01/2024",
        fullName: "Juan Mays",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "visit",
        location: "location1"
    },
    {
        id: 3,
        date: "22/01/2024",
        fullName: "Scott Henry",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "leave",
        location: "location1"
    },
    {
        id: 4,
        date: "22/01/2024",
        fullName: "Cody Menendez",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "destination",
        location: "location1"
    },
    {
        id: 5,
        date: "22/01/2024",
        fullName: "Jason Merino",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "moving",
        location: "location1"
    },
    {
        id: 6,
        date: "22/01/2024",
        fullName: "Kyle Aquino",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 7,
        date: "22/01/2024",
        fullName: "David Gaul",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 8,
        date: "22/01/2024",
        fullName: "John McCray",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 9,
        date: "22/01/2024",
        fullName: "Stephen Rash",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 10,
        date: "22/01/2024",
        fullName: "Juan Mays",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 11,
        date: "22/01/2024",
        fullName: "Scott Henry",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 12,
        date: "22/01/2024",
        fullName: "Cody Menendez",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
    {
        id: 13,
        date: "22/01/2024",
        fullName: "Jason Merino",
        punchedIn: "09:04 AM",
        punchedOut: "09:04 AM",
        duration: "6.00",
        type: "office",
        location: "location1"
    },
];

const yearDummyData = [
    {
        name: "Office",
        data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
    },
    {
        name: "Online",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
    },
    {
        name: "Leave",
        data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
    },
    {
        name: "Visit",
        data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
    },
    {
        name: "Destination",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
    },
    {
        name: "Moving",
        data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
    },
];

const monthDummyData = [
    {
        name: "Office",
        data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
    },
    {
        name: "Online",
        data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
    },
    {
        name: "Leave",
        data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
    },
    {
        name: "Visit",
        data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
    },
    {
        name: "Destination",
        data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
    },
    {
        name: "Moving",
        data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
    },
];

const weekDummyData = [
    {
        name: "Office",
        data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
    {
        name: "Online",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
    {
        name: "Leave",
        data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
    },
    {
        name: "Visit",
        data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
    },
    {
        name: "Destination",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
    {
        name: "Moving",
        data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
];

const latestTransactionDummyData = [
    {
        orderId: "#SK2545",
        billingName: "Jacob Hunter",
        orderdate: "04 Oct, 2019",
        total: "$392",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-paypal",
        paymentMethod: "Paypal",
    },
    {
        orderId: "#SK2544",
        billingName: "Ronald Taylor",
        orderdate: "04 Oct, 2019",
        total: "$404",
        paymentStatus: "Refund",
        methodIcon: "fab fa-cc-visa",
        paymentMethod: "Visa",
    },
    {
        orderId: "#SK2543",
        billingName: "Barry Dick",
        orderdate: "05 Oct, 2019",
        total: "$412",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-mastercard",
        paymentMethod: "Mastercard",
    },
    {
        orderId: "#SK2542",
        billingName: "Juan Mitchell",
        orderdate: "06 Oct, 2019",
        total: "$384",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-paypal",
        paymentMethod: "Paypal",
    },
    {
        orderId: "#SK2541",
        billingName: "Jamal Burnett",
        orderdate: "07 Oct, 2019",
        total: "$380",
        paymentStatus: "Chargeback",
        methodIcon: "fab fa-cc-visa",
        paymentMethod: "Visa",
    },
    {
        orderId: "#SK2540",
        billingName: "Neal Matthews",
        orderdate: "07 Oct, 2019",
        total: "$400",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-mastercard",
        paymentMethod: "Mastercard",
    },
];

const leaveTransactionDummyData = [
    {
        id: 1,
        date: "22/01/2024",
        fullName: "Stephen Rash",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "pending"
    },
    {
        id: 2,
        date: "22/01/2024",
        fullName: "Juan Mays",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "accepted"
    },
    {
        id: 3,
        date: "22/01/2024",
        fullName: "Scott Henry",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "pending"
    },
    {
        id: 4,
        date: "22/01/2024",
        fullName: "Cody Menendez",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "accepted"
    },
    {
        id: 5,
        date: "22/01/2024",
        fullName: "Jason Merino",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "rejected"
    },
    {
        id: 6,
        date: "22/01/2024",
        fullName: "Kyle Aquino",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "accepted"
    },
    {
        id: 7,
        date: "22/01/2024",
        fullName: "David Gaul",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "pending"
    },
    {
        id: 8,
        date: "22/01/2024",
        fullName: "John McCray",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "rejected"
    },
    {
        id: 9,
        date: "22/01/2024",
        fullName: "Stephen Rash",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "rejected"
    },
    {
        id: 10,
        date: "22/01/2024",
        fullName: "Juan Mays",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "rejected"
    },
    {
        id: 11,
        date: "22/01/2024",
        fullName: "Scott Henry",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "pending"
    },
    {
        id: 12,
        date: "22/01/2024",
        fullName: "Cody Menendez",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "rejected"
    },
    {
        id: 13,
        date: "22/01/2024",
        fullName: "Jason Merino",
        leaveType: "casual",
        from: "22/01/2024",
        to: "22/01/2024",
        balance: "3 Days",
        status: "pending"
    },
];

const AttendaceTransactionDummyData = [
    {
        date: '12/01/2024',
        location: "Location 1",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Stephen Rash",
    },
    {
        date: '12/01/2024',
        location: "Location 2",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Juan Mays",
    },
    {
        date: '12/01/2024',
        location: "Location 3",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Scott Henry",
    },
    {
        date: '12/01/2024',
        location: "Location 4",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Cody Menendez",
    },
    {
        date: '12/01/2024',
        location: "Location 5",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Jason Merino",
    },
    {
        date: '12/01/2024',
        location: "Location 6",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Kyle Aquino",
    },
    {
        date: '12/01/2024',
        location: "Location 7",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "David Gaul",
    },
    {
        date: '12/01/2024',
        location: "Location 8",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "John McCray",
    },
    {
        date: '12/01/2024',
        location: "Location 9",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Stephen Rash",
    },
    {
        date: '12/01/2024',
        location: "Location 10",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Juan Mays",
    },
    {
        date: '12/01/2024',
        location: "Location 11",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Scott Henry",
    },
    {
        date: '12/01/2024',
        location: "Location 12",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Cody Menendez",
    },
    {
        date: '12/01/2024',
        location: "Location 13",
        workHours: "950.32 H",
        staffNumber: 50,
        types: 'Destination, Visit',
        topStaff: "Jason Merino",
    },
];

export {
    attendanceDetailsDummyData,
    latestTransactionDummyData,
    monthDummyData,
    weekDummyData,
    yearDummyData,
    leaveTransactionDummyData,
    AttendaceTransactionDummyData
};
