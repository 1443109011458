const leaveTypesDummyData = [
  {
    id: 1,
    name: "casual",
    balance: 10,
    balanceCarriedOver: 1,
    MaximumLeaveDuration: 10,
    settings: {
      halfDay: true,
      reasoned: true,
      carriedOver: true,
    },
    preNoticePeriod: 30,
    description: "unscheduled, paid absence",
  },
  {
    id: 2,
    name: "holidays",
    balance: 10,
    balanceCarriedOver: 1,
    MaximumLeaveDuration: 3,
    settings: {
      halfDay: true,
      reasoned: false,
      carriedOver: true,
    },
    preNoticePeriod: 30,
    description: "scheduled time off for celebrations ",
  },
  {
    id: 3,
    name: "personal",
    balance: 10,
    balanceCarriedOver: 1,
    MaximumLeaveDuration: 10,
    settings: {
      halfDay: true,
      reasoned: true,
      carriedOver: false,
    },
    preNoticePeriod: 25,
    description: "scheduled time off for personal reasons",
  },
  {
    id: 4,
    name: "sick",
    balance: 10,
    balanceCarriedOver: 1,
    MaximumLeaveDuration: 1,
    settings: {
      halfDay: true,
      reasoned: false,
      carriedOver: false,
    },
    preNoticePeriod: 30,
    description: "time off for illness with pay.",
  },
  {
    id: 5,
    name: "Maternity/Paternity ",
    balance: 10,
    balanceCarriedOver: 0,
    MaximumLeaveDuration: 4,
    settings: {
      halfDay: false,
      reasoned: true,
      carriedOver: true,
    },
    preNoticePeriod: 0,
    description: "scheduled time off for new parents",
  },
]

const BeneficiariesEmployees = [
  {
    id: 1,
    name: "ahmed mohamed ibrahim",
    jopTitle: "UI-UX",
    phone: "012345678912",
    balance: 10,
  },
  {
    id: 2,
    name: "ali mahmoud ahmed",
    jopTitle: "Front-End",
    phone: "012345678912",
    balance: 10,
  },
  {
    id: 3,
    name: "shref mahmoud ali",
    jopTitle: "Back-End",
    phone: "012345678912",
    balance: 10,
  },
  {
    id: 4,
    name: "yassen mahmoud ahmed",
    jopTitle: "Mobile",
    phone: "012345678912",
    balance: 10,
  },
  {
    id: 5,
    name: "essam mahmoud ahmed",
    jopTitle: "Full-Stack",
    phone: "012345678912",
    balance: 10,
  },
  {
    id: 6,
    name: "hassan mahmoud ahmed",
    jopTitle: "DevOps",
    phone: "012345678912",
    balance: 10,
  },
]

export { leaveTypesDummyData, BeneficiariesEmployees }
