import React from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"

import GregorianHijriDate from "pages/Leave/VacationTypes/GregorianHijriDate"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const CreateEditVacationType = props => {
  const { modal, toggle, isEdit, vacationType } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (vacationType && vacationType.name) || "",
      dateFrom: (vacationType && vacationType.dateFrom) || "",
      dateTo: (vacationType && vacationType.dateTo) || "",
      balance: (vacationType && vacationType.balance) || "",
      description: (vacationType && vacationType.description) || "",
      annually: (vacationType && vacationType.annually) || false,
      workDuringVacation:
        (vacationType && vacationType.workDuringVacation) || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Name")),
      dateFrom: Yup.string().required(props.t("Please Enter Date From")),
      dateTo: Yup.string().required(props.t("Please Enter Date To")),
      balance: Yup.string().required(props.t("Please Enter Balance")),
      description: Yup.string().required(props.t("Please Enter Description")),
    }),
    onSubmit: values => {
      // TODO: submit values
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit") : props.t("Add")} {props.t("Vacation Type")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Name")}</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder={props.t("Insert Vacation Type Name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <GregorianHijriDate
                  label={props.t("Date From")}
                  name="dateFrom"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dateFrom || ""}
                  touchedError={validation.touched.dateFrom}
                  validationError={validation.errors.dateFrom}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <GregorianHijriDate
                  label={props.t("Date To")}
                  name="dateTo"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dateTo || ""}
                  touchedError={validation.touched.dateTo}
                  validationError={validation.errors.dateTo}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Balance")}</Label>
                  <Input
                    name="balance"
                    type="number"
                    placeholder={props.t("Insert Vacation Type Balance")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.balance || ""}
                    invalid={
                      validation.touched.balance && validation.errors.balance
                        ? true
                        : false
                    }
                  />
                  {validation.touched.balance && validation.errors.balance ? (
                    <FormFeedback type="invalid">
                      {validation.errors.balance}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">{props.t("Description")}</Label>
                  <Input
                    name="description"
                    type="text"
                    placeholder={props.t("Insert Vacation Type Description")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description &&
                      validation.errors.description
                        ? true
                        : false
                    }
                  />
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex gap-3">
                <Label className="form-label">{props.t("Annually")}</Label>
                <div className="form-check form-switch mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="annually"
                    defaultChecked
                  />
                </div>
              </Col>
              <Col className="d-flex gap-3">
                <Label className="form-label">
                  {props.t("Work During The Vacation")}
                </Label>
                <div className="form-check form-switch mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="workDuringVacation"
                    defaultChecked
                  />
                </div>
              </Col>
            </Row>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex gap-2">
                <button
                  type="submit"
                  className={`btn btn-primary save-customer`}
                >
                  {isEdit ? props.t("Save") : props.t("Add Vacation")}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

CreateEditVacationType.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CreateEditVacationType))
