import React, { useState } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import { jopsTitlesDummyData, genderDummyData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const CreateEditEmployee = props => {
  const { modal, toggle, isEdit, employee, avatar } = props
  const [selectedGender, setselectedGender] = useState(null)
  const [selectedJopTitle, setselectedJopTitle] = useState(null)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (employee && employee.id) || "",
      fullName: (employee && employee.fullName) || "",
      username: (employee && employee.username) || "",
      image: (employee && employee.image) || "",
      phone: (employee && employee.phone) || "",
      email: (employee && employee.email) || "",
      gender: (employee && employee.gender) || "",
      jopTitle: (employee && employee.jopTitle) || "",
      managerAssigned: (employee && employee.managerAssigned) || "",
    },
    validationSchema: Yup.object({
      id: Yup.string().required(props.t("Please Enter Id")),
      fullName: Yup.string().required(props.t("Please Enter Full Name")),
      username: Yup.string().required("Please Enter User Name"),
      // image: Yup.string().notRequired("Please Enter User Name"),
      phone: Yup.string().required(props.t("Please Enter Phone Number")),
      email: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          props.t("Please Enter Valid Email")
        )
        .required(props.t("Please Enter Email")),
      gender: Yup.string().required(props.t("Please Enter Gender")),
      jopTitle: Yup.string().required(props.t("Please Enter Jop Title")),
      // managerAssigned: Yup.string().notRequired("Please Enter Your Wallet Balance"),
    }),
    onSubmit: values => {
      console.log("toggle")
      if (isEdit) {
        const updateEmployee = {
          id: employee ? employee.id : 0,
          fullName: values.fullName,
          username: values.username,
          image: avatar,
          phone: values.phone,
          email: values.email,
          gender: values.gender,
          managerAssigned: values.managerAssigned,
          jopTitle: values.jopTitle,
        }
        // TODO: Dispatch
        validation.resetForm()
      } else {
        const newEmployee = {
          id: values["id"],
          fullName: values["fullName"],
          username: values["username"],
          image: avatar,
          phone: values["phone"],
          email: values["email"],
          gender: values["gender"],
          managerAssigned: values["managerAssigned"],
          jopTitle: values["jopTitle"],
        }
        // TODO: Dispatch
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit") : props.t("Add")} {props.t("Employee")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Full Name")}</Label>
                  <Input
                    name="fullName"
                    type="text"
                    placeholder={props.t("Insert Employee Full Name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fullName || ""}
                    invalid={
                      validation.touched.fullName && validation.errors.fullName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.fullName && validation.errors.fullName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.fullName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>{props.t("Gender")}</Label>
                  <Select
                    value={selectedGender}
                    onChange={selectedOption => {
                      setselectedGender(selectedOption)
                      validation.setFieldValue("gender", selectedOption.value)
                    }}
                    options={genderDummyData.map(gender => ({
                      value: gender,
                      label: gender,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("UserName")}</Label>
                  <Input
                    name="username"
                    type="text"
                    placeholder={props.t("Insert User Name")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Phone")}</Label>
                    <Input
                      name="phone"
                      type="number"
                      placeholder={props.t("Insert Employee Phone")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Id")}</Label>
                  <Input
                    name="id"
                    type="text"
                    placeholder={props.t("Insert Employee Id")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.id || ""}
                    invalid={
                      validation.touched.id && validation.errors.id
                        ? true
                        : false
                    }
                  />
                  {validation.touched.id && validation.errors.id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label>{props.t("Jop Title")}</Label>
                  <Select
                    value={selectedJopTitle}
                    onChange={selectedOption => {
                      setselectedJopTitle(selectedOption)
                      validation.setFieldValue("jopTitle", selectedOption.value)
                    }}
                    options={jopsTitlesDummyData.map(jopTitle => ({
                      value: jopTitle,
                      label: jopTitle,
                    }))}
                    className="select2-selection"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{props.t("Email")}</Label>
                  <Input
                    name="email"
                    type="text"
                    placeholder={props.t("Insert Employee Email")}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <div className="d-flex gap-2">
                <button
                  type="submit"
                  className={`btn btn-primary save-customer`}
                >
                  {!!isEdit ? props.t("Edit") : props.t("Add")}{" "}
                  {props.t("Employee")}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

CreateEditEmployee.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CreateEditEmployee))
