// import React, { useState, useEffect } from "react"
// import Select from "react-select"
// import { Button, Col, InputGroup, Row } from "reactstrap"
// import { trackingEmployees } from "pages/data"
// import classes from "./index.module.scss"

// const filterBy = ["name", "status", "typeOfAttendance", "jopTitle"]

// const HeaderForm = props => {
//   const { layoutType, setLayoutType } = props
//   const [selectedMulti, setselectedMulti] = useState(null)
//   const [multiSelectOptions, setMultiSelectOptions] = useState([])

//   // get multi select options
//   useEffect(() => {
//     setMultiSelectOptions([])
//     filterBy.map(filter => {
//       if (filter === "name") {
//         let options = trackingEmployees.map(emp => emp.name).filter(one => one)

//         options = new Set(options)
//         options = [...options].map(name => ({ label: name, value: name }))

//         setMultiSelectOptions(prevState => [
//           ...prevState,
//           { label: "name", options },
//         ])
//       } else if (filter === "status") {
//         let options = trackingEmployees
//           .map(emp => {
//             if (emp.punchIn && emp.punchOut) {
//               return "punchOut"
//             } else if (emp.punchIn) {
//               return "punchIn"
//             } else {
//               return "not Punch In Yet"
//             }
//           })
//           .filter(one => one)

//         options = new Set(options)
//         options = [...options].map(locationType => ({
//           label: locationType,
//           value: locationType,
//         }))

//         setMultiSelectOptions(prevState => [
//           ...prevState,
//           { label: "status", options },
//         ])
//       } else if (filter === "typeOfAttendance") {
//         let options = trackingEmployees
//           .map(emp => emp.locationType)
//           .filter(one => one)

//         options = new Set(options)
//         options = [...options].map(locationType => ({
//           label: locationType,
//           value: locationType,
//         }))

//         setMultiSelectOptions(prevState => [
//           ...prevState,
//           { label: "location Type", options },
//         ])
//       } else if (filter === "jopTitle") {
//         let options = trackingEmployees
//           .map(emp => emp.jopTitle)
//           .filter(one => one)

//         options = new Set(options)
//         options = [...options].map(jopTitle => ({
//           label: jopTitle,
//           value: jopTitle,
//         }))

//         setMultiSelectOptions(prevState => [
//           ...prevState,
//           { label: "jop Title", options },
//         ])
//       }
//     })
//   }, [])

//   function handleMulti(selectedMulti) {
//     setselectedMulti(selectedMulti)
//   }

//   return (
//     <Row className="gap-3">
//       <Col>
//         <div>
//           <InputGroup
//             style={{
//               border: "none",
//               padding: "0px",
//               width: "100%",
//               zIndex: 10,
//             }}
//           >
//             {multiSelectOptions.length ? (
//               <Select
//                 value={selectedMulti}
//                 isMulti={true}
//                 onChange={handleMulti}
//                 options={multiSelectOptions}
//                 className={`form-select p-0 ${classes.select_tracking}`}
//                 placeholder="Filter..."
//               />
//             ) : (
//               <></>
//             )}
//           </InputGroup>
//         </div>
//       </Col>

//       <Col className="d-flex justify-content-center gap-3">
//         {["grid", "map", "card"].map((layout, index) => (
//           <Button
//             key={index}
//             type="button"
//             onClick={() => setLayoutType(layout)}
//             color={layoutType === layout ? "light" : "primary"}
//             outline={layoutType === layout ? false : true}
//             className={`btn btn-${
//               layoutType === layout ? "primary" : "light"
//             } bg-${
//               layoutType === layout ? "primary" : "light"
//             } d-flex align-items-center justify-content-center px-4 py-2`}
//             style={{
//               textTransform: "capitalize",
//               borderRadius: "10px",
//               width: "112px",
//               height: "56px",
//             }}
//           >
//             <span style={{ fontSize: "16px", lineHeight: "24px" }}>
//               {layout}
//             </span>
//           </Button>
//         ))}
//       </Col>
//       {/* <Col xs="2" /> */}
//     </Row>
//   )
// }

// export default HeaderForm
import React, { useState, useEffect } from "react"
import Select from "react-select"
import { Button, Col, Input, InputGroup, Label, Row } from "reactstrap"
import { trackingEmployees } from "pages/data"
import classes from "./index.module.scss"

import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

// const filterBy = ["name", "status", "typeOfAttendance", "jopTitle"]
const filterBy = ["name", "jobTitle"]

const HeaderForm = props => {
  const [selectedFilterGroups, setSelectedFilterGroups] = useState("")
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [filterSelectionsOptions, setFilterSelectionsOptions] = useState([])

  const { layoutType, setLayoutType } = props

  function handleFilterSelect(selectedMulti) {
    setSelectedFilter(selectedMulti)
    console.log(selectedMulti)
  }

  useEffect(() => {
    const selectOpionDemo = []
    selectedFilterGroups === "name" &&
      trackingEmployees.map(emp =>
        selectOpionDemo.push({ id: emp.id, label: emp.name, name: emp.name })
      )
    selectedFilterGroups === "jobTitle" &&
      trackingEmployees.map(emp =>
        selectOpionDemo.push({
          id: emp.id,
          label: emp.jopTitle,
          jobTitle: emp.jopTitle,
        })
      )
    setFilterSelectionsOptions(selectOpionDemo)
    console.log(selectOpionDemo)
  }, [selectedFilterGroups])

  return (
    <Row className="gap-3 align-items-center">
      <Col xl={8}>
        <div className="">
          <Row>
            <Col md={2} className="p-md-0 mb-2 m-md-0">
              <select
                className={`form-select py-3 ${classes.selectInputSearch}`}
                onChange={e => setSelectedFilterGroups(e.target.value)}
              >
                <option value="">{props.t("Filter By")}</option>
                {filterBy.map((one, index) => (
                  <option key={index} value={one}>
                    {one}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="d-flex p-md-0">
              <Select
                value={selectedFilter}
                isMulti={true}
                onChange={e => {
                  handleFilterSelect(e)
                }}
                options={filterSelectionsOptions}
                className={`form-control ${classes.searchEmployeeInput} select2-selection p-0 tracking-filter`}
                getOptionLabel={filterSelectionsOptions =>
                  filterSelectionsOptions["label"]
                }
                getOptionValue={filterSelectionsOptions =>
                  filterSelectionsOptions["id"]
                }
              />
              <Label
                className={`input-group-text bg-primary text-white px-3 py-3 ${classes.searchIcon}`}
                style={{ cursor: "pointer" }}
              >
                <i className="bx bx-search font-size-20" />
              </Label>
            </Col>
          </Row>
        </div>
      </Col>

      <Col className="d-flex justify-content-center gap-2">
        {["grid", "map", "card"].map((layout, index) => (
          <Button
            key={index}
            type="button"
            onClick={() => setLayoutType(layout)}
            color={layoutType === layout ? "light" : "primary"}
            outline={layoutType === layout ? false : true}
            className={`btn btn-${
              layoutType === layout ? "primary" : "light"
            } bg-${
              layoutType === layout ? "primary" : "light"
            } d-flex align-items-center justify-content-center px-4 py-2 ${
              classes.tracking_btn
            }`}
            style={{}}
          >
            <span style={{ fontSize: "16px", lineHeight: "24px" }}>
              {props.t(layout)}
            </span>
          </Button>
        ))}
      </Col>
      {/* <Col xs="2" /> */}
    </Row>
  )
}

HeaderForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(HeaderForm))
