import React, { useState } from "react"
import classes from "../index.module.scss"
import { Button, Card, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const CoordinatesList = props => {
  const { radius, center } = props

  const [showModel, setShowModel] = useState(false)

  return (
    <>
      <Button
        color={showModel ? "danger" : "primary"}
        style={{
          position: "absolute",
          top: "60px",
          right: "10px",
          zIndex: 20,
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
        className="font-size-24 text-center d-flex align-items-center justify-content-center"
        onClick={() => setShowModel(prevState => !prevState)}
      >
        <i className={`mdi text-white mdi-eye${showModel ? "-off" : ""} p-0`} />
      </Button>
      {showModel ? (
        <div className={`${classes.coordinates_list} rounded`}>
          <Card
            className="p-3 rounded"
            style={{ width: "100%", height: "100%" }}
          >
            <h5 className="m-1" style={{ borderBottom: "1px solid" }}>
              {props.t("Fence Name")}
            </h5>
            <hr className="pt-3" />
            <h6>{props.t("Alert Notification")}</h6>
            <Row>
              <Col>
                <div className="form-check form-check-primary mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customCheckcolor2"
                  >
                    {props.t("On Enter")}
                  </label>
                </div>
              </Col>
              <Col>
                <div className="form-check form-check-primary mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customCheckcolor2"
                  >
                    {props.t("On Exit")}
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="form-check-label" htmlFor="customCheckcolor2">
                  {props.t("Lng")}
                </span>
              </Col>
              <Col>
                <span>
                  {radius ? Number.parseFloat(center.lng).toFixed(2) : 0}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="form-check-label" htmlFor="customCheckcolor2">
                  {props.t("Lat")}
                </span>
              </Col>
              <Col>
                <span>
                  {radius ? Number.parseFloat(center.lat).toFixed(2) : 0}
                </span>
              </Col>
            </Row>
            <hr className="pt-3" />
            <h6>{props.t("Radious")}</h6>
            <Row>
              <Col>
                <div className="form-check form-check-primary mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customCheckcolor2"
                  >
                    {Number.parseFloat(radius).toFixed(2)} {props.t("Meter")}
                  </label>
                </div>
              </Col>
            </Row>
            {/* {fenceCoordinates.length ? (
        <>
          <hr className="pt-3" />
          <h6>Coordinates</h6>
          <ul className="list-group list-group-flush flex flex-column gap-2 pt-2">
            {fenceCoordinates.map((coordinate, index) => (
              <Row key={index} className="flex align-items-center gap-3">
                <Col>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckcolor2"
                  />
                  </Col>
                  <Col>
                  <span>{Number.parseFloat(coordinate.lat).toFixed(4)}</span>
                </Col>
                <Col>
                  <span>{Number.parseFloat(coordinate.lng).toFixed(4)}</span>
                </Col>
                <Col>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={deleteCoordinateHandler.bind(null, index)}
                  >
                  <i
                  className="mdi mdi-trash-can-outline font-size-16 text-danger me-1"
                      id="deletetooltip"
                    ></i>
                  </span>
                </Col>
              </Row>
            ))}
          </ul>
        </>
      ) : (
        <></>
      )} */}
          </Card>
        </div>
      ) : null}
    </>
  )
}

CoordinatesList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CoordinatesList))
