const vacationTypesDummyData = [
  {
    id: 1,
    name: "labor day",
    balance: 1,
    fromDate: "01/05/2024",
    toDate: "02/05/2024",
    workDuringVacation: true,
    annually: true,
    description: "unscheduled, paid absence",
  },
  {
    id: 2,
    name: "Eid al-Fitr",
    balance: 3,
    fromDate: "01/10/1445",
    toDate: "04/10/1445",
    workDuringVacation: true,
    annually: true,
    description: "scheduled time off for celebrations ",
  },
  {
    id: 3,
    name: "Eid al-Adha",
    balance: 5,
    fromDate: "10/12/1445",
    toDate: "15/12/1445",
    workDuringVacation: false,
    annually: false,
    description: "scheduled time off for personal reasons",
  },
  {
    id: 4,
    name: "spring festival",
    balance: 1,
    fromDate: "06/05/2024",
    toDate: "07/05/2024",
    workDuringVacation: false,
    annually: false,
    description: "time off for illness with pay.",
  },
  {
    id: 5,
    name: "june 30 revolution",
    balance: 1,
    fromDate: "30/06/2024",
    toDate: "01/07/2024",
    workDuringVacation: false,
    annually: false,
    description: "scheduled time off for new parents",
  },
]

export { vacationTypesDummyData }
