import React from "react"
import { UncontrolledTooltip } from "reactstrap"

const TabelInfo = ({ header, info, id }) => {
  return (
    <div style={{ position: "relative" }}>
      {header}{" "}
      <div style={{ position: "absolute", top: "-12px", left: "-10px" }}>
        <i className="bx bx-info-circle font-size-16" id={id}></i>
        <UncontrolledTooltip placement="top" target={id}>
          {info}
        </UncontrolledTooltip>
      </div>
    </div>
  )
}

export { TabelInfo }
