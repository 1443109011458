import React, { useCallback, useContext, useState } from "react"
import { Card, Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import LeaveTypeList from "./LeaveTypeList"
import BeneficiariesModel from "./BeneficiariesModel/BeneficiariesModel"
import CreateEditLeaveType from "./CreateEditLeaveType"

import SelectedLeaveType, {
  SelectedLeaveTypeProvider,
} from "pages/Leave/LeaveTypes/context/selectedLeaveTypes"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const LeaveTypes = withRouter(
  withTranslation()(props => {
    //meta title
    document.title = props.t("Leave Types | Smart Village")

    const { selectedLeaveTypesList, toggleSpecificLeaveType } =
      useContext(SelectedLeaveType)
    const [beneficiariesModel, setBeneficiariesModel] = useState(false)
    const [leaveTypeModel, setLeaveTypeModel] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [leaveType, setLeaveType] = useState(null)

    const toggleBeneficiariesModel = () =>
      setBeneficiariesModel(prevState => !prevState)

    const toggleBeneficiariesModelAndRemoveSpecificLeaveType = () => {
      toggleSpecificLeaveType(null)
      setBeneficiariesModel(prevState => !prevState)
    }

    const toggleCreateEditLeaveTypeModel = () => {
      if (leaveTypeModel) setLeaveType(null)
      setLeaveTypeModel(prevState => !prevState)
    }

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Leave")}
            breadcrumbItem={props.t("Leave Types")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <div
                  className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
                  style={{ marginRight: "25px" }}
                >
                  <Button
                    color="primary"
                    className="btn-rounded"
                    onClick={() => {
                      setIsEdit(false)
                      toggleCreateEditLeaveTypeModel()
                    }}
                  >
                    <i className="mdi mdi-plus" /> {props.t("New leave type")}
                  </Button>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button
                      color="danger"
                      className="btn-rounded"
                      disabled={!selectedLeaveTypesList.length}
                    >
                      <i className="mdi mdi-trash-can-outline" />{" "}
                      {props.t("Deactivate Selected")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedLeaveTypesList.length})
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button
                      color={
                        !selectedLeaveTypesList.length ? "secondary" : "warning"
                      }
                      className="btn-rounded"
                      onClick={
                        toggleBeneficiariesModelAndRemoveSpecificLeaveType
                      }
                      disabled={!selectedLeaveTypesList.length}
                    >
                      <i className="fa fas fa-user-friends" />{" "}
                      {props.t("Beneficiaries")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedLeaveTypesList.length})
                    </span>
                  </div>
                </div>

                <LeaveTypeList
                  toggleCreateEditLeaveTypeModel={
                    toggleCreateEditLeaveTypeModel
                  }
                  setIsEdit={setIsEdit}
                  setLeaveType={setLeaveType}
                  toggleBeneficiariesModel={toggleBeneficiariesModel}
                />

                <BeneficiariesModel
                  modal={beneficiariesModel}
                  toggle={toggleBeneficiariesModel}
                />

                <CreateEditLeaveType
                  modal={leaveTypeModel}
                  toggle={toggleCreateEditLeaveTypeModel}
                  isEdit={isEdit}
                  leaveType={leaveType}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  })
)

LeaveTypes.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const Index = () => {
  return (
    <SelectedLeaveTypeProvider>
      <LeaveTypes />
    </SelectedLeaveTypeProvider>
  )
}

export default Index
