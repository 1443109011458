import React, { useState, useEffect, useContext } from "react"
import { Button, Card } from "reactstrap"
import { useNavigate, useLocation } from "react-router-dom"

import SelectedVacationType from "pages/Leave/VacationTypes/context/selectedVacationTypes"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Navigation = props => {
  const { specificVacationType } = useContext(SelectedVacationType)

  const [currentType, setCurrentType] = useState("or")
  const navigate = useNavigate()
  const location = useLocation()
  const type = new URLSearchParams(location.search).get("type")

  useEffect(() => {
    if (specificVacationType && type === "or") {
      navigate(`/leave/vacation-types?type=and`)
      return
    }
    if (type) {
      setCurrentType(type)
    }
  }, [type])

  if (specificVacationType) {
    return <></>
  }

  return (
    <Card className="py-2 px-4 my-1">
      <div className="d-flex flex-row justify-content-between">
        {["or", "and", "add"].map(one => (
          <Button
            key={one}
            type="button"
            onClick={() => {
              setCurrentType(one)
              navigate(`/leave/vacation-types?type=${one}`)
            }}
            color={currentType === one ? "light" : "primary"}
            outline={currentType === one ? false : true}
            className={`btn btn-${
              currentType === one ? "primary" : "light"
            } bg-${
              currentType === one ? "primary" : "light"
            } d-flex align-items-center justify-content-center px-4 py-2 cursor-pointer`}
            style={{
              textTransform: "capitalize",
              borderRadius: "10px",
            }}
          >
            {props.t(one)}
          </Button>
        ))}
      </div>
    </Card>
  )
}

Navigation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navigation))
