const leaveListDummyData = [
  {
    id: 1,
    name: "casual",
    balance: 10,
    date: "01/05/2024",
    leavePeriod: "03/05/2024 to 07/05/2024",
    status: "rejected",
    reasons: "We have work pressure",
    numberOfDays: 4,
    employeeName: "Ahmed Mohamed",
  },
  {
    id: 2,
    name: "holidays",
    balance: 10,
    date: "03/05/2024",
    leavePeriod: "03/05/2024 to 08/05/2024",
    status: "approved",
    reasons: "You can take only 2 days we have work",
    numberOfDays: 4,
    employeeName: "Ali Ali",
  },
  {
    id: 3,
    name: "personal",
    balance: 10,
    date: "04/05/2024",
    leavePeriod: "04/05/2024 to 07/05/2024",
    status: "pending",
    reasons: "",
    numberOfDays: 3,
    employeeName: "Nermeen Ahmed",
  },
  {
    id: 4,
    name: "sick",
    balance: 10,
    date: "05/05/2024",
    leavePeriod: "07/05/2024 to 09/05/2024 ",
    status: "rejected",
    reasons: "",
    numberOfDays: 2,
    employeeName: "Shref Ali",
  },
  {
    id: 1,
    name: "Maternity/Paternity ",
    balance: 10,
    date: "06/05/2024",
    leavePeriod: "07/05/2024 to 10/05/2024 ",
    status: "approved",
    reasons: "",
    numberOfDays: 3,
    employeeName: "Marwa Mahmoud",
  },
]

export { leaveListDummyData }
