import React, { useState, useContext } from "react"
import { Col, Row } from "reactstrap"
import useColor from "hooks/useColors"
import { avatar } from "pages/data"

import SelectedEmployee from "pages/Tracking/context/selectedEmployee"

import classes from "./index.module.scss"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const EmployeeList = props => {
  const { employees } = props
  const { colors } = useColor()
  const { employeeId, changeSelectedEmployeeId } = useContext(SelectedEmployee)

  return (
    <Row className={` ${classes.employee_list_container_wrapper}`}>
      {employees.map((employee, index) => (
        <Row
          onClick={changeSelectedEmployeeId.bind(null, employee.id)}
          key={index}
          className={` 
                    ${classes.employee_list_container}
                    ${
                      employeeId === employee.id &&
                      classes.employee_list_container_active
                    } p-2 p-xl-3`}
          style={{
            border: `1px solid ${
              employee.punchIn && employee.punchOut
                ? colors.punchOut
                : !employee.punchIn
                ? colors.notPunchIn
                : colors.punshIn
            }`,
          }}
        >
          <Col
            xs={3}
            md={12}
            xl={3}
            className="d-flex align-items-center justify-content-center"
            style={{ position: "relative" }}
          >
            <div
              className={classes.employee_list_image_background}
              style={{
                backgroundColor:
                  employee.punchIn && employee.punchOut
                    ? colors.punchOut
                    : !employee.punchIn
                    ? colors.notPunchIn
                    : colors.punshIn,
              }}
            />
            <img src={avatar} className={classes.employee_list_image} />
          </Col>
          <Col className="px-0">
            <h1 className={classes.employee_name}>{employee.name}</h1>
            <Row className=" gap-1 font-size-12">
              <span>{employee.jopTitle}</span>
              <Row>
                {employee.punchIn && employee.punchOut ? (
                  <>
                    <Col xl={6}>
                      <span>
                        {props.t("Punch In")}: {employee.punchIn}
                      </span>
                    </Col>
                    <Col xl={6}>
                      <span>
                        {props.t("Punch Out")}: {employee.punchOut}
                      </span>
                    </Col>
                  </>
                ) : employee.punchIn ? (
                  <span>
                    {props.t("Punch In")}: {employee.punchIn}
                  </span>
                ) : (
                  <span>{props.t("Not punched in yet")}</span>
                )}
              </Row>
              {employee.locationType ? (
                <span>{employee.locationType}</span>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      ))}
    </Row>
  )
}

EmployeeList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(EmployeeList))
