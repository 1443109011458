// import React from "react"
// import GoogleMapReact from "google-map-react"
// import { avatar } from "pages/data"
// import useColor from "hooks/useColors"
// import classes from "../index.module.scss"

// const AnyReactComponent = ({ selectedEmployee, colors }) => (
//   <div
//     className={`p-1  ${classes.employee_model_map}`}
//     style={{
//       borderRadius: "16px",
//       minWidth: "118px",
//       backgroundColor:
//         selectedEmployee.punchIn && selectedEmployee.punchOut
//           ? colors.punchOut
//           : !selectedEmployee.punchIn
//           ? colors.notPunchIn
//           : colors.punshIn,
//     }}
//   >
//     <div
//       style={{
//         borderBottom: `20px solid ${
//           selectedEmployee.punchIn && selectedEmployee.punchOut
//             ? colors.punchOut
//             : !selectedEmployee.punchIn
//             ? colors.notPunchIn
//             : colors.punshIn
//         }`,
//       }}
//       className={classes.employee_model_map_path}
//     ></div>
//     <div
//       className={`bg-light gap-0 d-flex flex-column justify-content-center align-items-center`}
//       style={{ borderRadius: "16px", width: "100%", height: "100%" }}
//     >
//       <p
//         className={`${classes.emp_map_model} pt-2 mb-0`}
//         style={{ textTransform: "capitalize", fontWeight: "bold" }}
//       >
//         {selectedEmployee.name}
//       </p>
//       <span className="pt-1 pb-2">{selectedEmployee.phone}</span>
//       <div style={{ position: "relative", height: "100%" }}>
//         <div
//           className={classes.employee_list_image_background}
//           style={{
//             backgroundColor:
//               selectedEmployee.punchIn && selectedEmployee.punchOut
//                 ? colors.punchOut
//                 : !selectedEmployee.punchIn
//                 ? colors.notPunchIn
//                 : colors.punshIn,
//           }}
//         />
//         <img src={avatar} className={classes.employee_list_image} />
//       </div>
//     </div>
//   </div>
// )

// const SingleEmployeeMap = props => {
//   const { selectedEmployee } = props
//   const { colors } = useColor()

//   const defaultProps = {
//     center: {
//       lat: 29.946600619112992,
//       lng: 32.5795946228014,
//     },
//     zoom: 2,
//   }

//   return (
//     <div
//       style={{
//         height: "73vh",
//         width: "100%",
//         borderRadius: "16px",
//         overflow: "hidden",
//       }}
//     >
//       {selectedEmployee ? (
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
//           defaultCenter={defaultProps.center}
//           defaultZoom={defaultProps.zoom}
//           center={{
//             lat: selectedEmployee.coordinates.lat,
//             lng: selectedEmployee.coordinates.lng,
//           }}
//           zoom={10}
//         >
//           <AnyReactComponent
//             lat={selectedEmployee.coordinates.lat}
//             lng={selectedEmployee.coordinates.lng}
//             selectedEmployee={selectedEmployee}
//             colors={colors}
//           />
//         </GoogleMapReact>
//       ) : (
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
//           defaultCenter={defaultProps.center}
//           defaultZoom={defaultProps.zoom}
//         ></GoogleMapReact>
//       )}
//     </div>
//   )
// }
// export default SingleEmployeeMap

import React, { useState, useEffect } from "react"
import Leaflet from "leaflet"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"
import "./map.css"
import classes from "../index.module.scss"
import useColor from "hooks/useColors"
import { avatar } from "pages/data"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"
delete Leaflet.Icon.Default.prototype._getIconUrl

const SimpleMap = props => {
  const { selectedEmployee } = props
  const { colors } = useColor()
  const [position, setPosition] = useState([0, 0])

  useEffect(() => {
    if (selectedEmployee) {
      console.log(selectedEmployee)
      setPosition([
        selectedEmployee.coordinates.lat,
        selectedEmployee.coordinates.lng,
      ])
    }
  }, [selectedEmployee])

  return selectedEmployee ? (
    <Map
      center={position}
      zoom={8}
      style={{ height: "73vh", borderRadius: "16px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={position}
        icon={
          new Leaflet.Icon({
            iconUrl:
              "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
            shadowUrl:
              "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          })
        }
      >
        <Popup>
          <div
            className={`p-1  ${classes.employee_model_leaflet_map}`}
            style={{
              borderRadius: "16px",
              minWidth: "118px",
              backgroundColor:
                selectedEmployee.punchIn && selectedEmployee.punchOut
                  ? colors.punchOut
                  : !selectedEmployee.punchIn
                  ? colors.notPunchIn
                  : colors.punshIn,
            }}
          >
            <div
              className={`bg-white gap-0 d-flex flex-column justify-content-center align-items-center pb-2`}
              style={{ borderRadius: "16px", width: "100%", height: "100%" }}
            >
              <p
                className={`${classes.emp_map_model} pt-2 mb-0`}
                style={{ textTransform: "capitalize", fontWeight: "bold" }}
              >
                {selectedEmployee.name}
              </p>
              <span className="pt-1 pb-2">{selectedEmployee.phone}</span>
              <div style={{ position: "relative", height: "100%" }}>
                <div
                  className={classes.employee_list_image_background}
                  style={{
                    backgroundColor:
                      selectedEmployee.punchIn && selectedEmployee.punchOut
                        ? colors.punchOut
                        : !selectedEmployee.punchIn
                        ? colors.notPunchIn
                        : colors.punshIn,
                  }}
                />
                <img src={avatar} className={classes.employee_list_image} />
              </div>
            </div>
          </div>
        </Popup>
      </Marker>
    </Map>
  ) : (
    <Map
      center={[29.946600619112992, 32.5795946228014]}
      zoom={2}
      style={{ height: "73vh", borderRadius: "16px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
    </Map>
  )
}

export default SimpleMap
// https://leaflet-extras.github.io/leaflet-providers/preview/
