import avatar from "../../assets/images/users/avatar-2.jpg";

const staffDummyData = [
    {
        id: 1,
        username: "Stephen Rash",
        image: avatar,
        phone: "325-250-1106",
        email: "StephenRash@teleworm.us",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 2,
        username: "Juan Mays",
        image: avatar,
        phone: "443-523-4726",
        email: "JuanMays@armyspy.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 3,
        username: "Scott Henry",
        image: avatar,
        phone: "704-629-9535",
        email: "ScottHenry@jourrapide.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 4,
        username: "Cody Menendez",
        image: avatar,
        phone: "701-832-5838",
        email: "CodyMenendez@armyspy.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 5,
        username: "Jason Merino",
        image: avatar,
        phone: "706-219-4095",
        email: "JasonMerino@dayrep.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 6,
        username: "Kyle Aquino",
        image: avatar,
        phone: "415-232-5443",
        email: "KyleAquino@teleworm.us",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 7,
        username: "David Gaul",
        image: avatar,
        phone: "314-483-4679",
        email: "DavidGaul@teleworm.us",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 8,
        username: "John McCray",
        image: avatar,
        phone: "253-661-7551",
        email: "JohnMcCray@armyspy.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 9,
        username: "Stephen Rash",
        image: avatar,
        phone: "325-250-1106",
        email: "StephenRash@teleworm.us",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 10,
        username: "Juan Mays",
        image: avatar,
        phone: "443-523-4726",
        email: "JuanMays@armyspy.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 11,
        username: "Scott Henry",
        image: avatar,
        phone: "704-629-9535",
        email: "ScottHenry@jourrapide.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 12,
        username: "Cody Menendez",
        image: avatar,
        phone: "701-832-5838",
        email: "CodyMenendez@armyspy.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
    {
        id: 13,
        username: "Jason Merino",
        image: avatar,
        phone: "706-219-4095",
        email: "JasonMerino@dayrep.com",
        gender: "mail",
        managerAssigned: "jone doe"
    },
];

const jopsTitlesDummyData = [
    "UI-UX",
    "Front-End",
    "Back-End",
    "Mobile",
    "Full-Stack",
    "DevOps",
    "QA",
    "Designer",
    "Support",
    "HR",
    "Project Manager",
    "Accountant",
    "Sales Manager",
    "Marketing Manager",
    "Manager",
    "Others",
];

const genderDummyData = [
    "Male",
    "Female",
];

export { staffDummyData, avatar, jopsTitlesDummyData, genderDummyData };