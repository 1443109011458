import React, { useContext } from "react"

import { Modal, ModalHeader, ModalBody } from "reactstrap"

import Navigation from "./Navigation"
import HeaderForm from "./HeaderForm"
import EmployeesList from "./employeesList/index"
import SelectedVacationType from "pages/Leave/VacationTypes/context/selectedVacationTypes"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const BeneficiariesModel = props => {
  const { modal, toggle } = props
  const location = useLocation()
  const type = new URLSearchParams(location.search).get("type")

  const { setSelecteAllEmployees, emplyeesList, selectedEmplyeesList } =
    useContext(SelectedVacationType)

  return (
    <Modal isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        {!type || type === "or" ? (
          <></>
        ) : (
          <>
            <input
              type="checkbox"
              className="form-check-input"
              style={{ marginLeft: "18px", transform: "scale(0.9)" }}
              onClick={setSelecteAllEmployees}
              checked={selectedEmplyeesList.length === emplyeesList.length}
            />{" "}
          </>
        )}
        {props.t("Beneficiaries")}
      </ModalHeader>
      <ModalBody>
        <Navigation />
        <HeaderForm />
        <EmployeesList toggle={toggle} />
      </ModalBody>
    </Modal>
  )
}

BeneficiariesModel.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(BeneficiariesModel))
