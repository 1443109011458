import React, { useState } from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import CreateLocation from "pages/LocationList/CreateLocation"
import EditLocationInformation from "pages/LocationList/EditLocationInformation"
import EditAssignment from "pages/LocationList/EditAssignment"
import LocationList from "pages/LocationList/LocationList"
import Geo from "pages/LocationList/Geo/Geo"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Location = props => {
  document.title = props.t("Location List | Smart Village")

  const [modal, setModal] = useState(false)
  const [editLocationInfomodal, setEditLocationInfoModal] = useState(false)
  const [editAssignmentModel, setEditAssignmentModel] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [location, setLocation] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState([])

  // Geo
  const [GeoModal, setGeoModal] = useState(false)

  const toggle = () => {
    setModal(prevState => !prevState)
    if (modal) setLocation(null)
  }

  const toggleLocationInformation = () => {
    setEditLocationInfoModal(prevState => !prevState)
    if (editLocationInfomodal) setLocation(null)
  }

  const toggleAssignment = () => {
    setEditAssignmentModel(prevState => !prevState)
    if (editAssignmentModel) setLocation(null)
  }

  const handleDeleteLocation = () => {
    if (location && location.id) {
      setDeleteModal(false)
    }
  }

  const toggleGeo = () => {
    setGeoModal(prevState => !prevState)
    if (GeoModal) setLocation(null)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteLocation}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <Breadcrumbs
            title={props.t("Location")}
            breadcrumbItem={props.t("Location List")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <div
                  className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
                  style={{ marginRight: "25px" }}
                >
                  <Button
                    color="primary"
                    className="btn-rounded ml-2"
                    onClick={() => {
                      toggle()
                    }}
                  >
                    <i className="mdi mdi-plus" /> {props.t("Add Location")}
                  </Button>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button color="danger" className="btn-rounded">
                      <i className="mdi mdi-trash-can-outline" />{" "}
                      {props.t("Deactivate Selected")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedLocation.length})
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button color="danger" className="btn-rounded">
                      <i className="mdi mdi-eye-off" />{" "}
                      {props.t("Hide Selected")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedLocation.length})
                    </span>
                  </div>
                </div>

                <CardBody>
                  {/* list */}
                  <LocationList
                    setLocation={setLocation}
                    setEditLocationInfoModal={setEditLocationInfoModal}
                    setEditAssignmentModel={setEditAssignmentModel}
                    setGeoModal={setGeoModal}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                  />

                  <EditLocationInformation
                    modal={editLocationInfomodal}
                    toggle={toggleLocationInformation}
                    location={location}
                  />

                  <EditAssignment
                    modal={editAssignmentModel}
                    toggle={toggleAssignment}
                    location={location}
                  />

                  <Geo
                    GeoModal={GeoModal}
                    toggle={toggleGeo}
                    location={location}
                  />

                  <CreateLocation
                    modal={modal}
                    toggle={toggle}
                    location={location}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Location.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Location))
