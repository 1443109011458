import React, { useMemo, useContext } from "react"
import {
  CardBody,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import TableContainer from "components/Common/TableContainer"
import { TabelInfo, BadgeApp } from "components/shared"
import { useNavigate } from "react-router-dom"

import SelectedLeaveType from "pages/Leave/LeaveTypes/context/selectedLeaveTypes"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const LeaveTypeList = props => {
  const {
    toggleCreateEditLeaveTypeModel,
    setIsEdit,
    setLeaveType,
    toggleBeneficiariesModel,
  } = props
  const navigate = useNavigate()

  const {
    leaveTypes,
    selectedLeaveTypesList,
    toggleSelectedLeaveTypes,
    toggleSpecificLeaveType,
  } = useContext(SelectedLeaveType)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: cellProps => {
          return (
            <input
              onChange={() => toggleSelectedLeaveTypes(cellProps.row.original)}
              checked={selectedLeaveTypesList.find(
                leaveType => leaveType.id === cellProps.row.original.id
              )}
              type="checkbox"
              className="form-check-input"
            />
          )
        },
      },
      {
        Header: props.t("Name"),
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: (
          <TabelInfo
            header={props.t("Balance")}
            id="balance"
            info={props.t("Dynamic according to each employee")}
          />
        ),
        accessor: "balance",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Balance Carried Over"),
        accessor: "balanceCarriedOver",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: (
          <TabelInfo
            header={props.t("Maximum Leave Duration")}
            id="MaximumLeaveDuration"
            info={props.t(
              "The maximum duration allowed for each leave request"
            )}
          />
        ),
        accessor: "MaximumLeaveDuration",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: (
          <TabelInfo
            header={props.t("Settings")}
            id="Settings"
            info={props.t("Half day ,past , reasoned")}
          />
        ),
        accessor: "settings",
        filterable: false,
        Cell: cellProps => {
          const leaveTypeData = cellProps.value
          return (
            <div className="d-flex justify-content-start">
              {leaveTypeData.halfDay ? (
                <BadgeApp color={"danger"} content={"half day"} />
              ) : (
                <></>
              )}
              {leaveTypeData.carriedOver ? (
                <BadgeApp color={"success"} content={"past"} />
              ) : (
                <></>
              )}
              {leaveTypeData.reasoned ? (
                <BadgeApp color={"warning"} content={"reasoned"} />
              ) : (
                <></>
              )}
            </div>
          )
        },
      },
      {
        Header: props.t("pre Notice Period"),
        accessor: "preNoticePeriod",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Description"),
        accessor: "description",
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Action"),
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => {}}>
                  <i
                    className="mdi mdi-bell-ring font-size-16 text-danger me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-danger">{props.t("Deactivate")}</span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Deactivate")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const leaveTypeData = cellProps.row.original
                    toggleSpecificLeaveType(leaveTypeData)
                    toggleBeneficiariesModel()
                    navigate("/leave/leave-types?type=and")
                  }}
                >
                  <i
                    className="fa fas fa-user-friends font-size-16 text-warning me-1"
                    id="deactivetooltip"
                  ></i>
                  <span className="text-warning">
                    {props.t("Beneficiaries")}
                  </span>
                  <UncontrolledTooltip placement="top" target="deactivetooltip">
                    {props.t("Beneficiaries")}
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    const leaveTypeData = cellProps.row.original
                    // handleEmployeeClick(leaveTypeData)
                    setIsEdit(true)
                    toggleCreateEditLeaveTypeModel()
                    setLeaveType(leaveTypeData)
                  }}
                >
                  <i
                    className="bx bx-edit font-size-16 me-1"
                    id="edittooltip"
                  ></i>
                  {props.t("Edit Leave")}
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    {props.t("Edit Leave")}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    [leaveTypes, props.t]
  )

  return (
    <CardBody>
      <TableContainer
        columns={columns}
        data={leaveTypes}
        isGlobalFilter={true}
        isAddOptions={false}
        customPageSize={5}
        allListSize={leaveTypes.length}
      />
    </CardBody>
  )
}

LeaveTypeList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(LeaveTypeList))
