import React, { useState, useCallback } from "react"
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import CreateEditEmployee from "pages/Staff/CreateEditEmployee"
import StaffList from "pages/Staff/StaffList"
import ManagementLeave from "pages/Staff/ManagementLeave"

import useColor from "hooks/useColors"
import avatar from "../../assets/images/users/avatar-2.jpg"
import { staffDummyData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Staff = props => {
  //meta title
  document.title = props.t("Staf List | Smart Village")

  const { activeLink } = useColor()
  const [modal, setModal] = useState(false)
  const [managementLeavemodal, setManagementLeaveModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [selectedEmployees, setSelectedEmployees] = useState([])

  const setTargetEmployee = useCallback(currEmployee => {
    setEmployee({
      id: currEmployee.id,
      username: currEmployee.username,
      image: currEmployee.image,
      phone: currEmployee.phone,
      email: currEmployee.email,
      gender: currEmployee.gender,
      managerAssigned: currEmployee.managerAssigned,
    })
  }, [])

  const handleEditEmployee = arg => {
    const employee = arg
    setTargetEmployee(employee)
    setIsEdit(true)
    toggle()
  }

  const handelEditManagementLeave = arg => {
    const employee = arg
    setTargetEmployee(employee)
    toggleManagementLeave()
  }

  const getCheckedEmployeesHandler = employeeData => {
    const emplyeeExist = selectedEmployees.find(
      employee => employee.id === employeeData.id
    )
    if (emplyeeExist) {
      setSelectedEmployees(prevState =>
        prevState.filter(employee => employee.id !== employeeData.id)
      )
    } else {
      setSelectedEmployees(prevState => [...prevState, employeeData])
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setEmployee(null)
    } else {
      setModal(true)
    }
  }

  const toggleManagementLeave = () => {
    if (managementLeavemodal) {
      setManagementLeaveModal(false)
      setEmployee(null)
    } else {
      setManagementLeaveModal(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Staff")}
            breadcrumbItem={props.t("Staff List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <div
                  className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
                  style={{ marginRight: "25px" }}
                >
                  <div>
                    <Button
                      color="primary"
                      className="btn-rounded"
                      onClick={() => {
                        setIsEdit(false)
                        toggle()
                      }}
                    >
                      <i className="mdi mdi-plus" /> {props.t("Add Employee")}
                    </Button>
                  </div>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button color="danger" className="btn-rounded">
                      <i className="mdi mdi-trash-can-outline" />{" "}
                      {props.t("Deactivate Selected")}
                    </Button>
                    <span className="me-2">
                      {props.t("Selected")} ({selectedEmployees.length})
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Button color="primaryThree" className="btn-rounded">
                      <i className="dripicons-download" />{" "}
                      {props.t("Export List")}
                    </Button>
                  </div>
                </div>
                <CardBody>
                  <StaffList
                    staffData={staffDummyData}
                    selectedEmployees={selectedEmployees}
                    activeLink={activeLink}
                    handleEditEmployee={handleEditEmployee}
                    getCheckedEmployeesHandler={getCheckedEmployeesHandler}
                    handelEditManagementLeave={handelEditManagementLeave}
                  />

                  <CreateEditEmployee
                    modal={modal}
                    toggle={toggle}
                    isEdit={isEdit}
                    employee={employee}
                    avatar={avatar}
                  />

                  <ManagementLeave
                    modal={managementLeavemodal}
                    toggle={toggleManagementLeave}
                    employee={employee}
                    avatar={avatar}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Staff.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Staff))
