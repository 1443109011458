// import React from "react"

// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
// import PropTypes from "prop-types"
// import { connect } from "react-redux"

// const LoadingContainer = () => <div>Loading...</div>

// const EditGeo = props => {
//   const { modal, toggle, location } = props

//   const [markerPosition, setMarkerPosition] = React.useState({
//     lat: 29.9764,
//     lng: 31.1329,
//   })
//   const selectedPlace = {}

//   const onMarkerClick = (props, marker, e) => {
//     if (e && e.latLng) {
//       const lat = e.latLng.lat()
//       const lng = e.latLng.lng()
//       setMarkerPosition({ lat, lng })
//     }
//   }

//   const handleMapClick = (mapProps, map, event) => {
//     const { latLng } = event
//     const lat = latLng.lat()
//     const lng = latLng.lng()
//     setMarkerPosition({ lat, lng })
//   }

//   const handleMarkerPositionChange = (mapProps, map, event) => {
//     const { latLng } = event
//     const lat = latLng.lat()
//     const lng = latLng.lng()
//     setMarkerPosition({ lat, lng })
//   }

//   return (
//     <Map
//       google={props.google}
//       zoom={14}
//       // style={{ width: "100%", height: "100%" }}
//       style={{ width: "100%", height: "80vh" }}
//       initialCenter={{ lat: 29.9764, lng: 31.1329 }}
//       onClick={handleMapClick}
//     >
//       <Marker
//         position={markerPosition}
//         onClick={onMarkerClick}
//         draggable={true}
//         onDragend={handleMarkerPositionChange}
//       />
//       <InfoWindow>
//         <div>
//           <h1>{selectedPlace.name}</h1>
//         </div>
//       </InfoWindow>
//     </Map>
//   )
// }

// EditGeo.propTypes = {
//   google: PropTypes.object,
// }

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_MAP_API_KEY,
//     LoadingContainer: LoadingContainer,
//     v: "3",
//   })(EditGeo)
// )

import React, { useState } from "react"
import Leaflet from "leaflet"
import { Map, TileLayer, Marker } from "react-leaflet"
import "../../../../node_modules/leaflet/dist/leaflet.css"

Leaflet.Icon.Default.imagePath = "../node_modules/leaflet"

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
})

const EditGeo = () => {
  const [state, setState] = useState({
    lat: 29.9764,
    lng: 31.1329,
  })

  const [zoom, setZoom] = useState(10)

  const customIcon = new Leaflet.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  })

  const handleMapClick = e => {
    const { lat, lng } = e.latlng
    setState({
      lat,
      lng,
    })
  }

  const handleZoomEnd = e => {
    // Update zoom state only when the user manually changes the zoom level
    if (e.target._animateToZoom !== zoom) {
      setZoom(e.target._animateToZoom)
    }
  }

  const position = [state.lat, state.lng]

  return (
    <Map
      center={position}
      zoom={zoom}
      style={{ height: "80vh" }}
      onClick={handleMapClick}
      onZoomend={handleZoomEnd}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={customIcon}></Marker>
    </Map>
  )
}

export default EditGeo
