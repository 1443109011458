import React from "react"

const BadgeApp = ({ color, content }) => {
  return (
    <span
      className={`badge badge-soft-${color} rounded-pill float-end ms-1 font-size-12`}
    >
      {content}
    </span>
  )
}

export { BadgeApp }
