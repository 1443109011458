import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const TopCities = props => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            {props.t("Current Month Attendance Type WH")}
          </CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>4369</h3>
            <p>{props.t("Attendance Working Hours")}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{props.t("Office")}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">1000 </h5>
                  </td>
                  <td>
                    <Progress
                      value="94"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{props.t("Online")}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">950 </h5>
                  </td>
                  <td>
                    <Progress
                      value="85"
                      color="primaryThree"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Leave</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">850 </h5>
                  </td>
                  <td>
                    <Progress
                      value="80"
                      color="primaryTwo"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr> */}
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{props.t("Visit")}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">800 </h5>
                  </td>
                  <td>
                    <Progress
                      value="70"
                      color="primary"
                      style={{ opacity: 0.5 }}
                      className="bg-transparent progress-sm text-primary-50"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{props.t("Destination")}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">752 </h5>
                  </td>
                  <td>
                    <Progress
                      value="60"
                      color="primaryThree"
                      style={{ opacity: 0.5 }}
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">{props.t("Moving")}</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">600 </h5>
                  </td>
                  <td>
                    <Progress
                      value="40"
                      color="primaryTwo"
                      style={{ opacity: 0.5 }}
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

TopCities.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(TopCities))
