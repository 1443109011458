import React, { useMemo } from "react"
import { Card, CardBody, Badge } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { leaveTransactionDummyData } from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const Leave = props => {
  const columns = useMemo(
    () => [
      {
        Header: props.t("Date"),
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Full Name"),
        accessor: "fullName",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Leave Type"),
        accessor: "leaveType",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("From"),
        accessor: "from",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("To"),
        accessor: "to",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Balance"),
        accessor: "balance",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
        },
      },
      {
        Header: props.t("Status"),
        accessor: "status",
        Cell: cellProps => {
          return (
            <>
              <Badge
                className={`p-2
                             ${
                               cellProps.value === "pending"
                                 ? "bg-primaryThree"
                                 : cellProps.value === "accepted"
                                 ? "bg-success"
                                 : "bg-danger"
                             }`}
              >
                {" "}
                {cellProps.value}{" "}
              </Badge>
            </>
          )
        },
      },
    ],
    [leaveTransactionDummyData, props.t]
  )
  return (
    <>
      <div className="mb-4 h4 card-title">
        {props.t("Latest Leave Transactions")}
      </div>
      <Card>
        <CardBody>
          <TableContainer
            columns={columns}
            data={leaveTransactionDummyData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={5}
            allListSize={leaveTransactionDummyData.length}
          />
        </CardBody>
      </Card>
    </>
  )
}

Leave.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Leave))
