import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import Select from "react-select"
import {
  locationTypesDummyData,
  locationsNamesDummyData,
  attendanceTypesDummyData,
} from "pages/data"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const EditLocationInformation = props => {
  const { modal, toggle, location } = props
  const [selectedNameOfLocation, setSelectedNameOfLocation] = useState(null)
  const [selectedAttendanceType, setSelectedAttendanceType] = useState(null)
  const [selectedLocationType, setSelectedLocationType] = useState(null)

  // update initail selected values
  useEffect(() => {
    if (!modal) return
    setSelectedNameOfLocation(
      location && location.nameOfLocation
        ? {
            value: location.nameOfLocation,
            label: location.nameOfLocation,
          }
        : null
    )
    setSelectedAttendanceType(
      location && location.attendanceType
        ? {
            value: location.attendanceType,
            label: location.attendanceType,
          }
        : null
    )
    setSelectedLocationType(
      location && location.locationType
        ? {
            value: location.locationType,
            label: location.locationType,
          }
        : null
    )
  }, [location])

  const submitHandler = e => {
    e.preventDefault()
    // TODO: submit logic && checks
    console.log("submitted")
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Edit Location Information")}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <Row>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-sm-2">
                {props.t("Name Of Location")}
              </Label>
              <Col sm="10">
                <Select
                  value={selectedNameOfLocation}
                  onChange={selectedOption => {
                    setSelectedNameOfLocation(selectedOption)
                  }}
                  options={locationsNamesDummyData.map(locationName => ({
                    value: locationName,
                    label: locationName,
                  }))}
                  className="select2-selection"
                />
              </Col>
            </FormGroup>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-sm-2">
                {props.t("Attendance Type (S)")}
              </Label>
              <Col sm="10">
                <Select
                  value={selectedAttendanceType}
                  onChange={selectedOption => {
                    setSelectedAttendanceType(selectedOption)
                  }}
                  options={attendanceTypesDummyData.map(type => ({
                    value: type,
                    label: type,
                  }))}
                  className="select2-selection"
                />
              </Col>
            </FormGroup>
            <FormGroup className="mb-4" row>
              <Label className="col-form-label col-sm-2">
                {props.t("Location Type")}
              </Label>
              <Col sm="10">
                <Select
                  value={selectedLocationType}
                  onChange={selectedOption => {
                    setSelectedLocationType(selectedOption)
                  }}
                  options={locationTypesDummyData.map(type => ({
                    value: type,
                    label: type,
                  }))}
                  className="select2-selection"
                />
              </Col>
            </FormGroup>
          </Row>
          <Row className="mt-5">
            <Col sm="2" />
            <Col>
              <div className="d-flex gap-2">
                <button type="submit" className={`btn btn-primary`}>
                  {props.t("Save Changes")}
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-cancel text-primary"
                >
                  {props.t("Cancel")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

EditLocationInformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(EditLocationInformation))
