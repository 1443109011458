// ------------------- google maps for this approach ----------------------
// import React, { useState } from "react"
// import {
//   Map,
//   InfoWindow,
//   Marker,
//   GoogleApiWrapper,
//   Polygon,
// } from "google-maps-react"
// import PropTypes from "prop-types"
// import { connect } from "react-redux"
// import CoordinatesList from "pages/LocationList/Geo/GeoFencing/coordinatesList"

// const LoadingContainer = () => <div>Loading...</div>

// const GeoFencing = props => {
//   const { google } = props

//   const [markerPosition, setMarkerPosition] = useState({
//     lat: 29.9764,
//     lng: 31.1329,
//   })
//   const [fenceCoordinates, setFenceCoordinates] = useState([])

//   const handleMapClick = (mapProps, map, clickEvent) => {
//     const { latLng } = clickEvent
//     const lat = latLng.lat()
//     const lng = latLng.lng()
//     const newPosition = { lat, lng }
//     setMarkerPosition(newPosition)
//     setFenceCoordinates([...fenceCoordinates, newPosition])
//   }

//   const renderFence = () => {
//     if (fenceCoordinates.length < 1) {
//       return null // Not enough points to form a polygon
//     }

//     return (
//       <Polygon
//         paths={fenceCoordinates}
//         strokeColor="#014421"
//         strokeOpacity={0.8}
//         strokeWeight={2}
//         fillColor="#014421"
//         fillOpacity={0.35}
//       />
//     )
//   }

//   return (
//     <div style={{ position: "relative", height: "80vh", width: "100%" }}>
//       <Map
//         google={google}
//         zoom={14}
//         // style={{ width: "100%", height: "100%" }}
//         style={{ width: "100%", height: "80vh" }}
//         initialCenter={{ lat: 29.9764, lng: 31.1329 }}
//         onClick={handleMapClick}
//       >
//         <Marker position={markerPosition} />
//         <InfoWindow>
//           <div>
//             <h1>
//               {markerPosition.lat}, {markerPosition.lng}
//             </h1>
//           </div>
//         </InfoWindow>
//         {renderFence()}
//       </Map>
//       <CoordinatesList
//         fenceCoordinates={fenceCoordinates}
//         setFenceCoordinates={setFenceCoordinates}
//       />
//     </div>
//   )
// }

// GeoFencing.propTypes = {
//   google: PropTypes.object,
// }

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_MAP_API_KEY,
//     LoadingContainer: LoadingContainer,
//     v: "3",
//   })(GeoFencing)
// )

// import React, { useState } from "react"
// import { Map, Marker, GoogleApiWrapper, Circle } from "google-maps-react"
// import PropTypes from "prop-types"
// import { connect } from "react-redux"

// const LoadingContainer = () => <div>Loading...</div>

// const GeoFencing = props => {
//   const { google } = props

//   const [markerPosition, setMarkerPosition] = useState({
//     lat: 29.9764,
//     lng: 31.1329,
//   })
//   const [circleRadius, setCircleRadius] = useState(100) // Initial radius in meters

//   const handleMapClick = (mapProps, map, clickEvent) => {
//     const { latLng } = clickEvent
//     const lat = latLng.lat()
//     const lng = latLng.lng()
//     setMarkerPosition({ lat, lng })

//     // Calculate the distance between the center marker and the clicked point
//     const distance = google.maps.geometry.spherical.computeDistanceBetween(
//       new google.maps.LatLng(markerPosition.lat, markerPosition.lng),
//       latLng
//     )

//     // Update the radius based on the distance
//     setCircleRadius(Math.round(distance))
//   }

//   return (
//     <div style={{ position: "relative", height: "80vh", width: "100%" }}>
//       <Map
//         google={google}
//         zoom={14}
//         style={{ width: "100%", height: "80vh" }}
//         initialCenter={{ lat: 29.9764, lng: 31.1329 }}
//         onClick={handleMapClick}
//       >
//         <Marker position={markerPosition} />
//         <Circle
//           center={markerPosition}
//           radius={circleRadius}
//           options={{
//             strokeColor: "#FF0000",
//             strokeOpacity: 0.8,
//             strokeWeight: 2,
//             fillColor: "#FF0000",
//             fillOpacity: 0.35,
//           }}
//         />
//       </Map>
//       <div>
//         <p>Right-click on the map to define the radius dynamically.</p>
//         <p>Current Radius: {circleRadius} meters</p>
//       </div>
//     </div>
//   )
// }

// GeoFencing.propTypes = {
//   google: PropTypes.object,
// }

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_MAP_API_KEY,
//     LoadingContainer: LoadingContainer,
//     v: "3",
//   })(GeoFencing)
// )

import React, { useState, useEffect, useRef } from "react"
import { Map, TileLayer, Circle, Popup, Marker } from "react-leaflet"
import L from "leaflet"
import "leaflet-draw/dist/leaflet.draw.css"
import "leaflet-draw"
import CoordinatesList from "pages/LocationList/Geo/GeoFencing/coordinatesList"

const GeoFencing = () => {
  const [center, setCenter] = useState({
    lat: 29.9764,
    lng: 31.1329,
  })
  const [radius, setRadius] = useState(0)
  const [zoom, setZoom] = useState(13)

  const mapRef = useRef()
  const drawnItemsRef = useRef()

  useEffect(() => {
    const map = mapRef.current.leafletElement
    const drawnItems = new L.FeatureGroup()

    drawnItemsRef.current = drawnItems // Save the reference

    const drawControl = new L.Control.Draw({
      draw: {
        circle: {
          shapeOptions: {
            color: "#014421", // Set the color to green
          },
        },
        marker: false,
        polyline: false,
        polygon: false,
        rectangle: false,
      },
      edit: {
        featureGroup: drawnItems,
        remove: true, // Enable delete button
      },
    })

    map.addLayer(drawnItems)
    map.addControl(drawControl)

    map.on("draw:created", e => {
      const type = e.layerType
      const layer = e.layer

      // Remove existing circles before adding a new one
      drawnItems.eachLayer(existingLayer => {
        if (existingLayer instanceof L.Circle) {
          drawnItems.removeLayer(existingLayer)
        }
      })

      if (type === "circle") {
        const newRadius = layer.getRadius()
        const center = layer.getLatLng()
        setCenter(center)
        setRadius(newRadius)
      }

      drawnItems.addLayer(layer)
    })

    map.on("draw:edited", e => {
      const layers = e.layers
      layers.eachLayer(layer => {
        if (layer instanceof L.Circle) {
          const newRadius = layer.getRadius()
          const center = layer.getLatLng()
          setCenter(center)
          setRadius(newRadius)
        }
      })
    })
  }, [])

  const handleMapClick = e => {
    const { lat, lng } = e.latlng
    setCenter({ lat, lng })
  }

  const position = [center.lat, center.lng]

  return (
    <div style={{ position: "relative", height: "80vh", width: "100%" }}>
      <Map
        ref={mapRef}
        center={position}
        zoom={zoom}
        style={{ height: "80vh" }}
        onClick={handleMapClick}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* <Circle center={position} radius={radius} color="#014421">
          <Popup>
            <div>
              <label>
                Latitude:
                <input
                  type="number"
                  value={center.lat}
                  onChange={e =>
                    setCenter({
                      ...center,
                      lat: parseFloat(e.target.value),
                    })
                  }
                />
              </label>
              <br />
              <label>
                Longitude:
                <input
                  type="number"
                  value={center.lng}
                  onChange={e =>
                    setCenter({
                      ...center,
                      lng: parseFloat(e.target.value),
                    })
                  }
                />
              </label>
              <br />
              <label>
                Radius (meters):
                <input
                  type="number"
                  value={radius}
                  onChange={e => setRadius(parseInt(e.target.value, 10))}
                />
              </label>
            </div>
          </Popup>
        </Circle> */}
      </Map>
      <CoordinatesList radius={radius} center={center} />
    </div>
  )
}

export default GeoFencing
