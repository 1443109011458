import React from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

import ApexRadial from "./ApexRadial"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"

const MonthlyEarning = props => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{props.t("Monthly WH")}</CardTitle>
          <Row>
            <Col sm="6">
              <p className="">{props.t("This month")}</p>
              <h3>4369:03</h3>
              <p className="">
                <span className="text-success me-2">
                  {" "}
                  12% <i className="mdi mdi-arrow-up"></i>{" "}
                </span>{" "}
                {props.t("From previous period")}
              </p>
              <div className="mt-4">
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  {props.t("View More")}{" "}
                  <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ApexRadial dataColors='["--bs-primary"]' />
              </div>
            </Col>
          </Row>
          <p className=" mb-0">
            {props.t("We craft digital, graphic and dimensional thinking")}.
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

MonthlyEarning.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MonthlyEarning))
